import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import { motion } from "framer-motion";
import AudioBox from "./AudioBox";

function Page5() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [selectedToken3, setSelectedToken3] = useState("token1");
  const [WavIcon1, setWavIcon1] = useState(null);
  const [WavIcon2, setWavIcon2] = useState(null);
  const [WavIcon3, setWavIcon3] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let wavImage1;
      if (selectedToken1 === "token1") {
        wavImage1 = wavBlack;
      } else {
        wavImage1 = wavBlack;
      }
      setWavIcon1(wavImage1);

      let wavImage2;
      if (selectedToken3 === "token9") {
        wavImage2 = wavBlack;
      } else {
        wavImage2 = wavBlack;
      }
      setWavIcon2(wavImage2);

      let wavImage3;
      if (selectedToken3 === "token10") {
        wavImage3 = wavBlack;
      } else {
        wavImage3 = wavBlack;
      }
      setWavIcon3(wavImage3);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2, selectedToken3]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else if (bubble === 2) {
      setSelectedToken2(token);
    } else if (bubble === 3) {
      setSelectedToken3(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">
            We got a home for free and David slept in the kitchen. Eli becomes
            King.
          </h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
              <p>March</p>
              <p>2020</p>
              <p>was</p>
              <p>a</p>
              <p>difficult</p>
              <p>period.</p>
              <p>David</p>
              <p>was</p>
              <p>going</p>
              <p>through</p>
              <p>a</p>
              <p>rough</p>
              <p>time</p>
              <p>with</p>
              <p>his</p>
              <p>mental</p>
              <p>health,</p>
              <p>and</p>
              <p>he</p>
              <p>was</p>
              <p>by</p>
              <p>far</p>
              <p>the</p>
              <p>most</p>
              <p>technical</p>
              <p>person</p>
              <p>on</p>
              <p>the</p>
              <p>team.</p>
              <p>Sahil</p>
              <p>and</p>
              <p>Eli</p>
              <p>tried</p>
              <p>to</p>
              <p>find</p>
              <p>someone</p>
              <p>to</p>
              <p>help</p>
              <p>them</p>
              <p>build</p>
              <p>an</p>
              <p>app</p>
              <p>while</p>
              <p>David</p>
              <p>was</p>
              <p>away.</p>

              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon1}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Eli wants a stud programmer</span>
                </div>
              </button>
              <p>Our</p>
              <p>parents</p>
              <p>wanted</p>
              <p>us</p>
              <p>to</p>
              <p>stop</p>
              <p>working</p>
              <p>on</p>
              <p>this</p>
              <p>and</p>
              <p>go</p>
              <p>back</p>
              <p>to</p>
              <p>school.</p>
              <p>We</p>
              <p>had</p>
              <p>raised</p>
              <p>no</p>
              <p>money</p>
              <p>and</p>
              <p>had</p>
              <p>no</p>
              <p>finished</p>
              <p>product</p>
              <p>to</p>
              <p>test.</p>
            </div>

            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <AudioBox
                  songTitle="Eli wants a stud programmer.m4a"
                  fileSize="111 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/eli+wants+a+stud+programmer.m4a"
                />
              )}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>Eventually,</p>
              <p>Sahil’s</p>
              <p>family</p>
              <p>friend</p>
              <p>said</p>
              <p>they</p>
              <p>could</p>
              <p>use</p>
              <p>his</p>
              <p>apartment</p>
              <p>in</p>
              <p>Bermondsey</p>
              <p>for</p>
              <p>the</p>
              <p>summer.</p>
              <button
                onClick={() => handleTokenClick("token2", 2)}
                className={`token ${
                  selectedToken2 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/photo1hall.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo1</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token3", 2)}
                className={`token ${
                  selectedToken2 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src=" https://typowebsitevideo.s3.amazonaws.com/photo2.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo2</span>
                </div>
              </button>
              <p>Him</p>
              <p>and</p>
              <p>Eli</p>
              <p>started</p>
              <p>religiously</p>
              <p>coding</p>
              <p>every</p>
              <p>day.</p>
              <p>Eli</p>
              <p>realized</p>
              <p>he’d</p>
              <p>have</p>
              <p>to</p>
              <p>take</p>
              <p>complete</p>
              <p>ownership</p>
              <p>of</p>
              <p>the</p>
              <p>product,</p>
              <p>and</p>
              <p>he</p>
              <p>did</p>
              <p>-</p>
              <p>he</p>
              <p>became</p>
              <p>the</p>
              <p>stud</p>
              <p>programmer.</p>
              <p>After</p>
              <p>months</p>
              <p>of</p>
              <p>nonstop</p>
              <p>work,</p>
              <p>he</p>
              <p>got</p>
              <p>chat</p>
              <p>to</p>
              <p>work.</p>

              <button
                onClick={() => handleTokenClick("token4", 2)}
                className={`token ${
                  selectedToken2 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_First+App+Worked.webp "
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">First app worked!.mp4</span>
                </div>
              </button>
              <p>We</p>
              <p>were</p>
              <p>ecstatic.</p>
            </div>

            <div className="bubble-bottom">
              {selectedToken2 === "token2" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/photo1hall.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken2 === "token3" && (
                <img
                  src=" https://typowebsitevideo.s3.amazonaws.com/photo2.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}
              {selectedToken2 === "token4" && (
                <motion.video
                  width="560"
                  height="310"
                  src=" https://typowebsitevideo.s3.amazonaws.com/First+App+Worked.mov"
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_First+App+Worked.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>David</p>
              <p>finally</p>
              <p>arrived</p>
              <p>in</p>
              <p>England</p>
              <p>after</p>
              <p>months.</p>

              <button
                onClick={() => handleTokenClick("token5", 3)}
                className={`token ${
                  selectedToken3 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_david+arriving+in+london.webp "
                      alt="David Arriving in London"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David arrives in London.mp4</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token6", 3)}
                className={`token ${
                  selectedToken3 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_david+moving+into+london+apt.webp "
                      alt="David Moving in 1"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David Moving in pt1.mp4</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token7", 3)}
                className={`token ${
                  selectedToken3 === "token7" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_david+moving+in+p2.webp "
                      alt="David Moving in 2"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David Moving in pt2.mp4</span>
                </div>
              </button>
              <p>It</p>
              <p>was</p>
              <p>a</p>
              <p>miserable</p>
              <p>living</p>
              <p>situation:</p>
              <p>David</p>
              <p>slept</p>
              <p>in</p>
              <p>the</p>
              <p>kitchen,</p>
              <p>and</p>
              <p>we</p>
              <p>were</p>
              <p>all</p>
              <p>anxious</p>
              <p>about</p>
              <p>bills.</p>

              <button
                onClick={() => handleTokenClick("token8", 3)}
                className={`token ${
                  selectedToken3 === "token8" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_d+playing+guitar+while+s+makes+omelette.webp "
                      alt="David Playing the guitar while making an omlette"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David the guitarist.mov</span>
                </div>
              </button>
              <p>But</p>
              <p>the</p>
              <p>magic</p>
              <p>of</p>
              <p>being</p>
              <p>in</p>
              <p>the</p>
              <p>same</p>
              <p>room</p>
              <p>made</p>
              <p>it</p>
              <p>all</p>
              <p>worthwhile.</p>
              <p>We</p>
              <p>knew</p>
              <p>that</p>
              <p>we</p>
              <p>had</p>
              <p>no</p>
              <p>choice</p>
              <p>but</p>
              <p>to</p>
              <p>work</p>
              <p>together,</p>
              <p>no</p>
              <p>matter</p>
              <p>how</p>
              <p>difficult</p>
              <p>it</p>
              <p>would</p>
              <p>be</p>
              <p>to</p>
              <p>make</p>
              <p>that</p>
              <p>happen.</p>
              <button
                onClick={() => handleTokenClick("token9", 3)}
                className={`token ${
                  selectedToken3 === "token9" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon2}
                      alt="reflecting on hard times"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    reflecting on hard times.m4a
                  </span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token10", 3)}
                className={`token ${
                  selectedToken3 === "token10" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon3}
                      alt="David being wrong"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David being wrong.m4a</span>
                </div>
              </button>
            </div>

            <div className="bubble-bottom">
              {selectedToken3 === "token5" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/david+arriving+in+london.qt "
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_david+arriving+in+london.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

              {selectedToken3 === "token6" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/david+moving+into+london+apt.qt "
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_david+moving+into+london+apt.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

              {selectedToken3 === "token7" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/david+moving+in+p2.qt "
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_david+moving+in+p2.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

              {selectedToken3 === "token8" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/d+playing+guitar+while+s+makes+omelette.mov"
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_d+playing+guitar+while+s+makes+omelette.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

              {selectedToken3 === "token9" && (
                <AudioBox
                  songTitle="Hard Times.mp3"
                  fileSize="112 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/Cofounders+reflecting+on+the+hard+times.m4a"
                />
              )}

              {selectedToken3 === "token10" && (
                <AudioBox
                  songTitle="David being wrong.m4a"
                  fileSize="910 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/David+on+being+wrong+about+living+together.m4a"
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page5;
