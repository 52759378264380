import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import { motion } from "framer-motion";
import AudioBox from "./AudioBox";

function Page6() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [WavIcon, setWavIcon] = useState(null);

  useEffect(() => {
    async function loadSvg() {


      let wavImage;
      if (selectedToken1 === "token1") {
        wavImage = wavBlack;
      } else {
        wavImage = wavBlack;
      }
      setWavIcon(wavImage);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">Our first app.</h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
            <p>The</p>
<p>first</p>
<p>actual</p>
<p>software</p>
<p>app</p>
<p>we</p>
<p>built</p>
<p>was</p>
<p>a</p>
<p>messaging</p>
<p>app</p>
<p>that</p>
<p>let</p>
<p>you</p>
<p>save</p>
<p>&</p>
<p>share</p>
<p>parts</p>
<p>of</p>
<p>your</p>
<p>text</p>
<p>threads.</p>
<p>Your</p>
<p>archive</p>
<p>contained</p>
<p>lists</p>
<p>of</p>
<p>your</p>
<p>favorite</p>
<p>threads</p>
<p>—</p>
<p>moments</p>
<p>from</p>
<p>your</p>
<p>life,</p>
<p>your</p>
<p>most</p>
<p>meaningful</p>
<p>conversations,</p>
<p>and</p>
<p>fascinating</p>
<p>conversations</p>
<p>you’ve</p>
<p>saved</p>
<p>from</p>
<p>other</p>
<p>people.</p>
<p>You</p>
<p>could</p>
<p>move</p>
<p>these</p>
<p>segments</p>
<p>between</p>
<p>chats</p>
<p>to</p>
<p>share</p>
<p>contexts</p>
<p>quickly</p>
<p>and</p>
<p>easily,</p>
<p>replacing</p>
<p>the</p>
<p>screenshot.</p>
<p>We</p>
<p>thought</p>
<p>our</p>
<p>chat</p>
<p>histories</p>
<p>said</p>
<p>more</p>
<p>about</p>
<p>who</p>
<p>we</p>
<p>were</p>
<p>than</p>
<p>our</p>
<p>photo</p>
<p>reels,</p>
<p>but</p>
<p>they</p>
<p>were</p>
<p>completely</p>
<p>hidden.</p>

<button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon}
                      alt="Bylines is a Tenet"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Bylines is a Tenet.</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src=" https://typowebsitevideo.s3.amazonaws.com/VT_Stewart+Butterfield+on+Slack+%26+Consumer.webp "
                      alt="Stewart Butterfield on Slack and Consumer"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Stewart Butterfield on Slack and Consumer</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_First+App+Worked.webp "
                      alt="App Working"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Moment the 1st app worked!</span>
                </div>
              </button>



          
            </div>

            <div className="bubble-bottom">


            {selectedToken1 === "token1" && (
                <AudioBox
                  songTitle="Bylines is a Tenet.mp3"
                  fileSize="117 KB"
                  audioSrc=" https://typowebsitevideo.s3.amazonaws.com/ByLines+is+Tenet.m4a"
                />
              )}
              {selectedToken1 === "token2" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/Stewart+Butterfield+on+Slack+%26+Consumer.mov "
                  poster=" https://typowebsitevideo.s3.amazonaws.com/VT_Stewart+Butterfield+on+Slack+%26+Consumer.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

{selectedToken1 === "token3" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/First+App+Worked.mov"
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_First+App+Worked.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}






            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page6;
