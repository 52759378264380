import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import { motion } from "framer-motion";
import AudioBox from "./AudioBox";

function Page12() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [selectedToken3, setSelectedToken3] = useState("token1");
  const [WavIcon1, setWavIcon1] = useState(null);
  const [WavIcon2, setWavIcon2] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let wavImage1;
      if (selectedToken1 === "token2") {
        wavImage1 = wavBlack;
      } else {
        wavImage1 = wavBlack;
      }
      setWavIcon1(wavImage1);

      let wavImage2;
      if (selectedToken2 === "token4") {
        wavImage2 = wavBlack;
      } else {
        wavImage2 = wavBlack;
      }
      setWavIcon2(wavImage2);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else if (bubble === 2) {
      setSelectedToken2(token);
    } else if (bubble === 3) {
      setSelectedToken3(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">WAGMI.</h1>
          {/* Bubble 1 */}

          <div className="bubble">
            <div className="bubble-top">
              <p>WAGMI</p>
              <p>WAGMI</p>
              <p>WAGMI.</p>
              <p>We</p>
              <p>learned</p>
              <p>that’s</p>
              <p>what</p>
              <p>everyone</p>
              <p>said</p>
              <p>in</p>
              <p>crypto-land.</p>
              <p>We</p>
              <p>had</p>
              <p>plenty</p>
              <p>of</p>
              <p>friends</p>
              <p>in</p>
              <p>the</p>
              <p>DAO</p>
              <p>world</p>
              <p>and</p>
              <p>NFT</p>
              <p>world,</p>
              <p>because</p>
              <p>we’d</p>
              <p>been</p>
              <p>fascinating</p>
              <p>by</p>
              <p>online</p>
              <p>community</p>
              <p>and</p>
              <p>art</p>
              <p>since</p>
              <p>the</p>
              <p>beginning.</p>
              <p>We’d</p>
              <p>thought</p>
              <p>about</p>
              <p>pitching</p>
              <p>ourselves</p>
              <p>for</p>
              <p>crypto</p>
              <p>a</p>
              <p>year</p>
              <p>before</p>
              <p>this:</p>
              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_s+persuading+d%26e+that+we+should+pitch+ourselves+as+for+DAOs.webp "
                      alt="Sahil persuading e&d on crypto"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    Sahil persuading e&d on crypto
                  </span>
                </div>
              </button>
              <p>and</p>
              <p>then</p>
              <p>Jake</p>
              <p>convinced</p>
              <p>us</p>
              <p>of</p>
              <p>the</p>
              <p>idea.</p>
              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon1}
                      alt="Jake"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">jake on crypto</span>
                </div>
              </button>
              <p>There</p>
              <p>was</p>
              <p>no</p>
              <p>widespread</p>
              <p>messaging</p>
              <p>platform</p>
              <p>that</p>
              <p>let</p>
              <p>one</p>
              <p>wallet</p>
              <p>commmunicate</p>
              <p>with</p>
              <p>another</p>
              <p>wallet:</p>
              <p>obviously,</p>
              <p>we</p>
              <p>should</p>
              <p>build</p>
              <p>it.</p>
              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_recording+first+crypto+pitch+(soma).webp "
                      alt="First crypto pitch"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    recodring first crypto pitch (soma).mp4
                  </span>
                </div>
              </button>
            </div>

            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/s+persuading+d%26e+that+we+should+pitch+ourselves+as+for+DAOs.mov"
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_s+persuading+d%26e+that+we+should+pitch+ourselves+as+for+DAOs.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

              {selectedToken1 === "token2" && (
                <AudioBox
                  songTitle="Jake on crypto.mp3"
                  fileSize="113 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/jake+asks+if+we've+considered+crypto.m4a"
                />
              )}

              {selectedToken1 === "token3" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/recording+first+crypto+pitch+(soma).mp4 "
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_recording+first+crypto+pitch+(soma).webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>But</p>
              <p>now</p>
              <p>we</p>
              <p>had</p>
              <p>to</p>
              <p>take</p>
              <p>that</p>
              <p>vague</p>
              <p>thesis</p>
              <p>about</p>
              <p>crypto</p>
              <p>messaging</p>
              <p>and</p>
              <p>turn</p>
              <p>it</p>
              <p>into</p>
              <p>a</p>
              <p>coherent</p>
              <p>product.</p>
              <p>We</p>
              <p>talked</p>
              <p>to</p>
              <p>everyone</p>
              <p>we</p>
              <p>knew,</p>
              <p>and</p>
              <p>a</p>
              <p>pseudonymous</p>
              <p>dude</p>
              <p>called</p>
              <p>tankbottoms</p>
              <p>ended</p>
              <p>up</p>
              <p>giving</p>
              <p>Sahil</p>
              <p>the</p>
              <p>winning</p>
              <p>idea</p>
              <button
                onClick={() => handleTokenClick("token4", 2)}
                className={`token ${
                  selectedToken2 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon2}
                      alt="Tankbottom"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    tankbottoms on chat widget for crypto dapps.m4a
                  </span>
                </div>
              </button>
              <p>(his</p>
              <p>own</p>
              <p>project</p>
              <p>folded</p>
              <p>a</p>
              <p>few</p>
              <p>months</p>
              <p>later,</p>
              <p>and</p>
              <p>that’s</p>
              <p>when</p>
              <p>we</p>
              <p>began</p>
              <p>getting</p>
              <p>concerned</p>
              <p>about</p>
              <p>the</p>
              <p>market.)</p>
            </div>

            <div className="bubble-bottom">
              {selectedToken2 === "token4" && (
                <AudioBox
                  songTitle="tankbottoms on chat widget for crypto dapps.m4a"
                  fileSize="171 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/tankbottoms+gave+sahil+the+idea+to+make+a+chat+widget+for+crypto+dapps.m4a"
                />
              )}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
            <p>We</p>
<p>built</p>
<p>a</p>
<p>chat</p>
<p>widget</p>
<p>that</p>
<p>allowed</p>
<p>a</p>
<p>developer</p>
<p>to</p>
<p>plug-in</p>
<p>a</p>
<p>chat</p>
<p>feature</p>
<p>into</p>
<p>their</p>
<p>dApp</p>
<p>with</p>
<p>a</p>
<p>few</p>
<p>lines</p>
<p>of</p>
<p>code.</p>

<button
              onClick={() => handleTokenClick("token5", 3)}
              className={`token ${
                selectedToken3 === "token5" ? "selected" : ""
              }`}
            >
              <div className="tokenPill">
                <div className="thumbnail">
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/3032351826_carrier_pigeon_delivering_a_message_painted_by_picasso.webp"
                    alt="Token Thumbnail"
                    className="thumbnailImg"
                  ></img>
                </div>

                <span className="tokenText">photo1</span>
              </div>
            </button>

            <button
              onClick={() => handleTokenClick("token6", 3)}
              className={`token ${
                selectedToken3 === "token6" ? "selected" : ""
              }`}
            >
              <div className="tokenPill">
                <div className="thumbnail">
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/Balancer.webp"
                    alt="Token Thumbnail"
                    className="thumbnailImg"
                  ></img>
                </div>

                <span className="tokenText">photo2</span>
              </div>
            </button>

            <button
              onClick={() => handleTokenClick("token7", 3)}
              className={`token ${
                selectedToken3 === "token7" ? "selected" : ""
              }`}
            >
              <div className="tokenPill">
                <div className="thumbnail">
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/IMG_2417.webp"
                    alt="Token Thumbnail"
                    className="thumbnailImg"
                  ></img>
                </div>

                <span className="tokenText">photo3</span>
              </div>
            </button>

            <button
              onClick={() => handleTokenClick("token8", 3)}
              className={`token ${
                selectedToken3 === "token8" ? "selected" : ""
              }`}
            >
              <div className="tokenPill">
                <div className="thumbnail">
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/IMG_0605.webp"
                    alt="Token Thumbnail"
                    className="thumbnailImg"
                  ></img>
                </div>

                <span className="tokenText">photo4</span>
              </div>
            </button>
<p>The</p>
<p>promise</p>
<p>was</p>
<p>an</p>
<p>interopable</p>
<p>inbox</p>
<p>that</p>
<p>followed</p>
<p>your</p>
<p>wallet</p>
<p>across</p>
<p>all</p>
<p>of</p>
<p>your</p>
<p>web3</p>
<p>experiences.</p>
<p>It</p>
<p>was</p>
<p>a</p>
<p>good</p>
<p>idea,</p>
<p>but</p>
<p>it</p>
<p>relied</p>
<p>on</p>
<p>other</p>
<p>crypto</p>
<p>projects.</p>

            </div>


            <div className="bubble-bottom">
              {selectedToken3 === "token5" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/3032351826_carrier_pigeon_delivering_a_message_painted_by_picasso.webp"
                  alt="logo"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken3 === "token6" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Balancer.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken3 === "token7" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_2417.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken3 === "token8" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_0605.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  
  );
}

export default Page12;
