import React, { useEffect, useState, useLayoutEffect } from "react";
import linkBlack from "./IconSVG.svg";
import { motion } from "framer-motion";
// import PdfViewer from "./PdfViewer";
import PreviewBox from "./PreviewBox";


function Page2() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [LinkImage1, setLinkImage1] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let svgLink1;
      if (selectedToken1 === "token2") {
        svgLink1 = linkBlack;
      } else {
        svgLink1 = linkBlack;
      }
      setLinkImage1(svgLink1);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">Romania Says No.</h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
              <p>Sahil</p>
              <p>moved</p>
              <p>to</p>
              <p>Romania</p>
              <p>with</p>
              <p>his</p>
              <p>then-girlfriend.</p>
              <p>David</p>
              <p>was</p>
              <p>meant</p>
              <p>to</p>
              <p>move-in</p>
              <p>with</p>
              <p>them</p>
              <p>too,</p>
              <p>and</p>
              <p>he</p>
              <p>was</p>
              <p>on</p>
              <p>his</p>
              <p>way</p>
              <p>from</p>
              <p>London</p>
              <p>when</p>
              <p>the</p>
              <p>Romanian</p>
              <p>government</p>
              <p>suddenly</p>
              <p>decided</p>
              <p>to</p>
              <p>change</p>
              <p>its</p>
              <p>COVID</p>
              <p>border</p>
              <p>restrictions.</p>
              <p>He</p>
              <p>lugged</p>
              <p>three</p>
              <p>suitcases</p>
              <p>and</p>
              <p>a</p>
              <p>guitar</p>
              <p>between</p>
              <p>four</p>
              <p>different</p>
              <p>airports,</p>
              <p>but</p>
              <p>couldn’t</p>
              <p>make</p>
              <p>it</p>
              <p>over.</p>
              <p>Sahil</p>
              <p>visited</p>
              <p>the</p>
              <p>embassy</p>
              <p>and</p>
              <p>had</p>
              <p>a</p>
              <p>dentist</p>
              <p>sign</p>
              <p>a</p>
              <p>letter</p>
              <p>to</p>
              <p>convince</p>
              <p>the</p>
              <p>government</p>
              <p>that</p>
              <p>David</p>
              <p>had</p>
              <p>an</p>
              <p>urgent</p>
              <p>medical</p>
              <p>procedure</p>
              <p>that</p>
              <p>could</p>
              <p>only</p>
              <p>be</p>
              <p>completed</p>
              <p>in</p>
              <p>Bucharest.</p>
              <p>It</p>
              <p>didn’t</p>
              <p>work.</p>
            </div>

            <div className="bubble-bottom">
              {/* You can put content here */}
            </div>
            </div>


            {/* Bubble 2 */}

            <div className="bubble">
              <div className="bubble-top">
                <p>At</p>
                <p>this</p>
                <p>point,</p>
                <p>there</p>
                <p>were</p>
                <p>four</p>
                <p>of</p>
                <p>us</p>
                <p>working</p>
                <p>on</p>
                <p>the</p>
                <p>media</p>
                <p>project.</p>
                <p>Eli</p>
                <p>was</p>
                <p>recovering</p>
                <p>from</p>
                <p>brain</p>
                <p>surgery,</p>
                <p>so</p>
                <p>he</p>
                <p>still</p>
                <p>wasn’t</p>
                <p>a</p>
                <p>founder.</p>
                <p>Sahil</p>
                <p>had</p>
                <p>only</p>
                <p>convinced</p>
                <p>Eli</p>
                <p>to</p>
                <p>join</p>
                <p>the</p>
                <p>project</p>
                <p>by</p>
                <p>convincing</p>
                <p>him</p>
                <p>it</p>
                <p>was</p>
                <p>really</p>
                <p>about</p>
                <p>global</p>
                <p>politics,</p>
                <p>and</p>
                <p>in</p>
                <p>product</p>
                <p>brainstorms</p>
                <p>he</p>
                <p>often</p>
                <p>seemed</p>
                <p>convinced</p>
                <p>that</p>
                <p>it</p>
                <p>was.</p>

                 <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Eli_Sahil_Africa.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Sahil & Eli on Africa & the text-URL UI</span>
                </div>
              </button>
                <p>We</p>
                <p>made</p>
                <p>a</p>
                <p>new</p>
                <p>deck</p>
                <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={LinkImage1} alt="Token" />
                  <span className="tokenText">deck</span>
                </div>
              </button>
                <p>and</p>
                <p>started</p>
                <p>designing.</p>
                <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Early_Sketckes.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Early Sketches</span>
                </div>
              </button>
              </div>

              <div className="bubble-bottom">
                {selectedToken1 === "token1" && (
                  <motion.video
                    width="560"
                    height="310"
                    src="https://typowebsitevideo.s3.amazonaws.com/Sahil+%26+Eli+on+Africa+and+the+text-URL+UI.mov"
                    poster="https://typowebsitevideo.s3.amazonaws.com/Eli_Sahil_Africa.webp"
                    controls
                    loop
                    muted
                    style={{
                      width: "100%",
                      height: "104%",
                      borderRadius: "16px",
                      backgroundColor:"black"
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    Your browser does not support the video tag.
                  </motion.video>
                )}

                {selectedToken1 === "token2" && <PreviewBox 
              
              imageURL="https://typowebsitevideo.s3.amazonaws.com/Lines_Deck_2_image.png"
              logoURL="https://typowebsitevideo.s3.amazonaws.com/pdflogo.png"
              ItemURL="https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf"
              websiteName="ByLines Deck 2"
              description="Making it easy for people to create and consume better conversations"
                
                // "https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf" 
                
                
                />}

                {selectedToken1 === "token3" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/Early_Sketckes.webp"
                    alt="Invite"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        
      }
    </div>
  );
}

export default Page2;
