import React, { useEffect } from "react";
import "./App.css";
import WebFont from "webfontloader";
import LandingPage from './LandingPage';
import Jobs from "./Jobs";
import AboutUs from "./About";
import Waitlist from "./Waitlist";
import SuccessPage from "./SuccessPage";
import VIP from "./VIP";
import FloatingNavbar from "./FloatingNavbar";
import { NavbarProvider } from './NavbarContext';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useLocation  } from "react-router-dom";
import WhatNavigator from "./WhatNavigator";
import HistoryNavigator from "./HistoryNavigator";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
import Page16 from "./Page16";
import Page17 from "./Page17";
import Page18 from "./Page18";
import Page19 from "./Page19";
import Page20 from "./Page20";
import ScratchPage from "./ScratchCanvas";



const DynamicPage = () => {
  let { pageNumber } = useParams();
  const pageNum = parseInt(pageNumber, 10);

  if (isNaN(pageNum) || pageNum < 1 || pageNum > 14) {
    return <Navigate to="/studioday/from-bylines-to-typo/1" replace />;
  }

  // You can add logic here to render the appropriate page based on pageNum
  const PageComponent = {
    1: Page1,
     2: Page2, 3: Page3, 4: Page4, 5: Page5, 
    6: Page6, 7: Page7, 8: Page8, 9: Page9, 10: Page10, 
    11: Page11, 12: Page12, 13: Page13, 14: Page14
  }[pageNum];

  return <HistoryNavigator><PageComponent /></HistoryNavigator>;
};

const WhatNavigatorDynamic = () => {
  let { action } = useParams();
  const validActions = {
    'drawing': <Page15 />,
    'compose': <Page16 />,
    'chat': <Page17 />,
    'consume': <Page18 />,
    'preview': <Page19 />,
    'expand': <Page20 />
  };

  const PageComponent = validActions[action];

  if (!PageComponent) {
    return <Navigate to="/studioday/what/drawing" replace />;
  }

  return <WhatNavigator>{PageComponent}</WhatNavigator>;
};

const Layout = () => {
  const location = useLocation();
  const navbarPaths = ['/', '/waitlist', '/success', '/jobs', '/about', '/vip'];
  const shouldShowNavbar = navbarPaths.includes(location.pathname);

  return (
    <>
      {shouldShowNavbar && <FloatingNavbar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/waitlist" element={<Waitlist />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/vip" element={<VIP />} />
        <Route path="/studioday/from-bylines-to-typo/" element={<Navigate to="/studioday/from-bylines-to-typo/1" replace />} />
        <Route path="/scratchpage" element={<ScratchPage />} />
        <Route path="/studioday/from-bylines-to-typo/:pageNumber" element={<DynamicPage />} />
        <Route path="/studioday/what/:action" element={<WhatNavigatorDynamic />} />
      </Routes>
    </>
  );
};

function App() {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: [
          "SFPro",
          "SFPro Medium",
          "SFPro Semibold",
          "SFPro Bold",
          "SF Mono",
          "Bardi",
        ],
        urls: ["/Fonts.css"],
      },
    });
  }, []);

  return (
    <NavbarProvider>
      <Router>
        <div className="App">
          <Layout />
        </div>
      </Router>
    </NavbarProvider>
  );
}

export default App;