import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import AudioBox from "./AudioBox";

function Page13() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [WavIcon, setWavIcon] = useState(null);

  useEffect(() => {
    async function loadSvg() {

      let wavImage;
      if (selectedToken2 === "token7") {
        wavImage = wavBlack;
      } else {
        wavImage = wavBlack;
      }
      setWavIcon(wavImage);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">The crypto market crashed. The product failed.</h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
            <p>Our</p>
<p>GTM</p>
<p>motion</p>
<p>failed.</p>
<p>We</p>
<p>were</p>
<p>the</p>
<p>easiest</p>
<p>way</p>
<p>to</p>
<p>plug-in</p>
<p>chat</p>
<p>to</p>
<p>a</p>
<p>crypto</p>
<p>app,</p>
<p>but</p>
<p>there</p>
<p>weren’t</p>
<p>many</p>
<p>crypto</p>
<p>apps</p>
<p>and</p>
<p>there</p>
<p>weren’t</p>
<p>many</p>
<p>reasons</p>
<p>for</p>
<p>people</p>
<p>to</p>
<p>chat</p>
<p>on</p>
<p>them.</p>
<p>We</p>
<p>did</p>
<p>our</p>
<p>first</p>
<p>product</p>
<p>integration</p>
<p>with</p>
<p>an</p>
<p>NFT</p>
<p>game</p>
<p>called</p>
<p>Sail,</p>
<p>and</p>
<p>it</p>
<p>felt</p>
<p>good</p>
<p>to</p>
<p>FINALLY</p>
<p>have</p>
<p>usage</p>
<p>on</p>
<p>a</p>
<p>product.</p>
<p>But</p>
<p>the</p>
<p>only</p>
<p>way</p>
<p>we</p>
<p>were</p>
<p>going</p>
<p>to</p>
<p>win</p>
<p>is</p>
<p>if</p>
<p>we</p>
<p>could</p>
<p>use</p>
<p>the</p>
<p>distribution</p>
<p>advantage</p>
<p>of</p>
<p>wallet</p>
<p>#s</p>
<p>increasing</p>
<p>to</p>
<p>bootstrap</p>
<p>the</p>
<p>messaging</p>
<p>platform.</p>
<p>That</p>
<p>number</p>
<p>was</p>
<p>decreasing,</p>
<p>and</p>
<p>although</p>
<p>the</p>
<p>existing</p>
<p>ones</p>
<p>were</p>
<p>open</p>
<p>to</p>
<p>integrating</p>
<p>our</p>
<p>product,</p>
<p>they</p>
<p>weren’t</p>
<p>desperate</p>
<p>for</p>
<p>it:</p>
<p>they</p>
<p>were</p>
<p>more</p>
<p>focused</p>
<p>on</p>
<p>trying</p>
<p>to</p>
<p>survive.</p>

              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_11.53.29_PM.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo1</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_11.52.46_PM.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo2.</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_2.37.00_AM.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo3</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_2.35.49_AM.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo4</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token5", 1)}
                className={`token ${
                  selectedToken1 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/IMG_2792.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo5.</span>
                </div>
              </button>
            </div>
            
            <div className="bubble-bottom">
         
            {selectedToken1 === "token1" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_11.53.29_PM.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token2" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_11.52.46_PM.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token3" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_2.37.00_AM.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token4" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_2023-04-30_at_2.35.49_AM.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token5" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_2792.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}
            </div>
          </div>


          <div className="bubble">
            <div className="bubble-top">
            <p>We’d</p>
<p>had</p>
<p>an</p>
<p>idea</p>
<p>that</p>
<p>this</p>
<p>might</p>
<p>happen,</p>
<p>and</p>
<p>we</p>
<p>saw</p>
<p>the</p>
<p>writing</p>
<p>on</p>
<p>the</p>
<p>wall</p>
<p>pretty</p>
<p>quickly</p>
<p>—</p>
<p>the</p>
<p>FTX</p>
<p>fallout</p>
<p>made</p>
<p>it</p>
<p>brutally</p>
<p>clear</p>

<button
                onClick={() => handleTokenClick("token6", 2)}
                className={`token ${
                  selectedToken2 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/IMG_1054.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">FTX Screenshot.</span>
                </div>
              </button>
<p>Crypto</p>
<p>messaging</p>
<p>had</p>
<p>been</p>
<p>a</p>
<p>good</p>
<p>way</p>
<p>to</p>
<p>raise,</p>
<p>but</p>
<p>we</p>
<p>didn’t</p>
<p>believe</p>
<p>it</p>
<p>was</p>
<p>a</p>
<p>valuable</p>
<p>opportunity</p>
<p>on</p>
<p>a</p>
<p>meaningful</p>
<p>timeline.</p>

<button
                onClick={() => handleTokenClick("token7", 2)}
                className={`token ${
                  selectedToken2 === "token7" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">hellerstein, bardi & s on chat v crypto chat</span>
                </div>
              </button>
<p>When</p>
<p>we</p>
<p>realized</p>
<p>we</p>
<p>needed</p>
<p>to</p>
<p>pivot,</p>
<p>we</p>
<p>made</p>
<p>the</p>
<p>difficult</p>
<p>decision</p>
<p>to</p>
<p>part</p>
<p>ways</p>
<p>with</p>
<p>two</p>
<p>incredible</p>
<p>people,</p>
<p>Ethan</p>
<p>and</p>
<p>Kanda.</p>
<p>It</p>
<p>was</p>
<p>time</p>
<p>to</p>
<p>go</p>
<p>back</p>
<p>to</p>
<p>our</p>
<p>roots,</p>
<p>and</p>
<p>this</p>
<p>time</p>
<p>we</p>
<p>wanted</p>
<p>to</p>
<p>build</p>
<p>for</p>
<p>ourselves</p>
<p>first</p>
<p>and</p>
<p>foremost.</p>




            </div>

            <div className="bubble-bottom">
 

              {selectedToken2 === "token6" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_1054.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}


              {selectedToken2 === "token7" && (
                <AudioBox
                  songTitle="hellerstein, bardi & s on chat v crypto chat.mp3"
                  fileSize="511 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/hellerstein%2C+bardi+%26+s+on+chat+v+crypto+chat.m4a"
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page13;
