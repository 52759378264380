import React, { useEffect, useState, useLayoutEffect } from "react";
import IconSVG from "./IconSVG.svg";
import PreviewBox from "./PreviewBox";
import Aos from "aos";
import 'aos/dist/aos.css';



function About() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token6");
  const [selectedToken3, setSelectedToken3] = useState("token1");
  const [selectedToken4, setSelectedToken4] = useState("token1");


  useEffect(() => {
    async function loadSvg() {}

    loadSvg();
  }, [selectedToken1, selectedToken2, selectedToken3, selectedToken3 ]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else if (bubble === 2) {
      setSelectedToken2(token);
    } else if (bubble === 3) {
      setSelectedToken3(token);
    }
    else if (bubble === 4) {
      setSelectedToken4(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
      // Initialize AOS
      Aos.init({
        duration: 800 // or your desired configuration
    });
    
    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  

  return (
    <div className="page-content">
      <div className="app-container">
        {
          <div className="main-content">
            {/* Bubble 1 */}

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>We're</p> <p>a</p> <p>small</p> <p>team</p>
                <p>of</p> <p>craftspeople</p> <p>who</p> <p>believe</p>
                <p>art</p> <p>and</p> <p>technology</p> <p>must</p> <p>mix.</p>
                <p>We’re</p>
                <p>motivated</p>
                <p>by</p>
                <p>mission,</p>
                <p>craft,</p>
                <p>and</p>
                <p>people</p>
<button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/whiteboarding.webp "
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">whiteboarding.jpg</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/lockedin.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">locked in.jpg</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/offsite.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">offsite.jpg</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/candid.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">candid.jpg</span>
                </div>
              </button>

              </div>

              <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/whiteboarding.webp "
                    alt="Whiteboarding"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
                {selectedToken1 === "token2" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/lockedin.webp"
                    alt="Locked in"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
                {selectedToken1 === "token3" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/offsite.webp"
                    alt="Offsite"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
                {selectedToken1 === "token4" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/candid.webp"
                    alt="Candid"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
              </div>
            </div>


            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
              <p>Some</p>
              <p>moments</p>
              <p>captured</p>
              <p>at</p>
              <p>our</p>
              <p>v1</p>
              <p>product</p>
              <p>unveiling:</p>


<button
                onClick={() => handleTokenClick("token6", 2)}
                className={`token ${
                  selectedToken2 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/tryingtypo.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">trying typo.jpg</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token7", 2)}
                className={`token ${
                  selectedToken2 === "token7" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/unveiling.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">unveiling setup.jpg</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token8", 2)}
                className={`token ${
                  selectedToken2 === "token8" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/demo.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">demo.jpg</span>
                </div>
              </button>

              </div>

              <div className="bubble-bottom">
              {selectedToken2 === "token6" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/tryingtypo.webp"
                    alt="Trying Typo"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
                              {selectedToken2 === "token7" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/unveiling.webp"
                    alt="Unveiling"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
                              {selectedToken2 === "token8" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/demo.webp"
                    alt="Demo"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
              <p>Where</p>
              <p>to</p>
              <p>find</p>
              <p>us:</p>
              <button
                  onClick={() => handleTokenClick("token1", 3)}
                  className={`token ${
                    selectedToken3 === "token1" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Instagram.com</span>
                  </div>
                </button>

                <button
                  onClick={() => handleTokenClick("token2", 3)}
                  className={`token ${
                    selectedToken3 === "token2" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">X.com</span>
                  </div>
                </button>

                <button
                  onClick={() => handleTokenClick("token3", 3)}
                  className={`token ${
                    selectedToken3 === "token3" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Youtube.com</span>
                  </div>
                </button>

              </div>

              <div className="bubble-bottom">
              {selectedToken3 === "token1" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://static.cdninstagram.com/rsrc.php/v3/yI/r/VsNE-OHk_8a.png"
                    ItemURL="https://www.instagram.com/tyyyyyyyyyyypo/"
                    websiteName="Typo* on Instagram"
                    description="A more ~editorial~ pov"
                  />
                )}

                {selectedToken3 === "token2" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://abs.twimg.com/responsive-web/client-web/icon-svg.ea5ff4aa.svg"
                    ItemURL="https://twitter.com/tyyyyyyyyyyypo"
                    websiteName="Typo on x.com"
                    description="Sometimes we create moodboards, sometimes we'll have product updates here."
                  />
                )}

                {selectedToken3 === "token3" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://www.youtube.com/s/desktop/28b0985e/img/favicon_144x144.png"
                    ItemURL="https://www.youtube.com/@tyyyyyyyyyyypo/videos"
                    websiteName="Typo* on Youtube"
                    description="Checkout our vlogs for a bts look at how we're building the company."
                  />
                )}
              </div>
            </div>



            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
              <p>Obligatory</p>
              <p>legal</p>
              <p>stuff:</p>

                <button
                  onClick={() => handleTokenClick("token1", 4)}
                  className={`token ${
                    selectedToken4 === "token1" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Terms of Service</span>
                  </div>
                </button>

                <button
                  onClick={() => handleTokenClick("token2", 4)}
                  className={`token ${
                    selectedToken4 === "token2" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Privacy Policy</span>
                  </div>
                </button>



  
              </div>

              <div className="bubble-bottom">
              {selectedToken4 === "token1" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://typowebsitevideo.s3.amazonaws.com/blue+logo.svg"
                    ItemURL="https://typo-public-documents.s3.amazonaws.com/TermsOfUse.html"
                    websiteName="Terms of Service"
                    description="Visit the link to read more."
                  />
                )}

                {selectedToken4 === "token2" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://typowebsitevideo.s3.amazonaws.com/blue+logo.svg"
                    ItemURL="https://typo-public-documents.s3.amazonaws.com/PrivacyPolicy.html"
                    websiteName="Privacy Policy"
                    description="Visit the link to read more."
                  />
                )}

      

              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default About;
