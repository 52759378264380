import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import clearScreenImage from "./clear_screen.svg";
import linkWhite from "./linkwhite.svg";
import linkBlue from "./linkblue.svg";
import waitBlue from "./waitblue.svg";
import waitWhite from "./waitwhite.svg";
import youtubeLogo from "./YouTube.png";
import xLogo from "./x.png";
import instagramLogo from "./Instagram.png"
// import {ReactComponent as LinkWhite} from './linkwhite.svg';
import { motion } from "framer-motion";

function ScratchCanvas() {
  const mainCanvasRef = useRef(null);
  const tempCanvasRef = useRef(null);
  const historyCanvasRef = useRef(null);
  const [canDraw, setCanDraw] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [TokenImage, setTokenImage] = useState(null);
  const [LinkImage1, setLinkImage1] = useState(null);
  const [LinkImage2, setLinkImage2] = useState(null);
  const [LinkImage3, setLinkImage3] = useState(null);
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [bubblesVisible, setBubblesVisible] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [countryCode, setCountryCode] = useState("+1"); // Default to United States

  const Modal = () => (
    <div className="modal-overlay">
      <div className="modal-content">Draw with your finger</div>
    </div>
  );

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };



  const disableScroll = () => {
    console.log("scroll disabled")
    let all = document.querySelectorAll(":not(.logo-container)");
    // document.getElementsByClassName
    all.forEach(element => {
        element.style.overflow = "hidden";
        element.style.overflowY = "hidden"
    })
  };

  const enableScroll = () => {
    console.log("scroll enabled")
    let all = document.querySelectorAll(":not(.logo-container)");
    all.forEach(element => {
        element.style.overflow = "auto";
        element.style.overflow = "auto"
    })
  };

  const handleChangeNumber = (event) => {
    const value = event.target.value;

    if (/^[\d\s()+-]*$/.test(value)) {
      // Allow digits, spaces, brackets, +, and -
      setField2(value);

      const digitsOnly = value.replace(/\D/g, ""); // Remove non-digit characters
      const validPhoneNumber =
        digitsOnly.length >= 9 && digitsOnly.length <= 11;

      setIsPhoneNumberValid(validPhoneNumber);

      // Reset error message if phone number is valid or if the invalid character error is no longer relevant
      if (
        validPhoneNumber ||
        (!validPhoneNumber &&
          errorMessage === "Invalid characters detected in phone number.")
      ) {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("Invalid characters detected in phone number.");
    }
  };
  const handleLogoClick = () => {
    setBubblesVisible((prev) => {

      const newIsBubbleVisible = !prev;

      if (newIsBubbleVisible) {
        enableScroll();
      } else {
        disableScroll();
      }

      if (prev) {
        // Only show the modal if it hasn't been shown before
        if (!hasModalBeenShown) {
          setShowModal(true);
          setHasModalBeenShown(true);
        }
        setCanDraw(true);
        return false;
      } else {
        setShowModal(false);
        setCanDraw(false);
        return true;
      }
    });
  };

  const handleField1Change = (event) => {
    setField1(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const digitsOnly = field2.replace(/\D/g, ""); // Remove non-digit characters

    // Check if the phone number has a valid length (9-11 digits)
    if (digitsOnly.length < 9 || digitsOnly.length > 11) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }
    // Check if the fields are empty
    if (!field1.trim() || !field2.trim()) {
      setErrorMessage("All fields are mandatory!");
      return;
    }

    if (!isPhoneNumberValid) {
      alert("Please enter a valid phone number.");
      return;
    }

    const data = {
      name: field1,
      phone: field2,
      countryCode: countryCode,
    };

    try {
      const response = await fetch(
        "https://api.apispreadsheets.com/data/lBFuJzf0qrWxZqA5/",
        {
          method: "POST",
          body: JSON.stringify({ data }),
        }
      );

      if (response.status === 201) {
        // SUCCESS
        console.log("Form submitted successfully");
        setIsSubmitted(true);
      } else {
        // ERROR
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    async function loadSvg() {
      let svgToken;
      if (selectedToken1 === "token2") {
        svgToken = waitBlue;
      } else {
        svgToken = waitWhite;
      }
      setTokenImage(svgToken);

      let svgLink1;
      if (selectedToken2 === "token1") {
        svgLink1 = linkBlue;
      } else {
        svgLink1 = linkWhite;
      }
      setLinkImage1(svgLink1);

      let svgLink2;
      if (selectedToken2 === "token2") {
        svgLink2 = linkBlue;
      } else {
        svgLink2 = linkWhite;
      }
      setLinkImage2(svgLink2);

      let svgLink3;
      if (selectedToken2 === "token3") {
        svgLink3 = linkBlue;
      } else {
        svgLink3 = linkWhite;
      }
      setLinkImage3(svgLink3);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);


  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  const clearCanvas = (canvasRef) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Set the fill color to purple
    context.fillStyle = "#FFFFFF";

    // Fill the canvas with the specified color
    context.fillRect(0, 0, canvas.width, canvas.height);
  };

  const logoRef = useRef(null);
  const logoImageRef = useRef(null);

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {
    const mainCanvas = mainCanvasRef.current;
    const tempCanvas = tempCanvasRef.current;
    const historyCanvas = historyCanvasRef.current;
    const logo = logoRef.current;
    const W = window.innerWidth;
    const H = window.innerHeight;

    let isDrawing = false;
    let isLogoFollowing = false;
    let points = [];

    // Create a function to update the canvas dimensions
    function updateDimensions() {
      const W = window.innerWidth;
      const H = window.innerHeight;

      const mainCanvasContext = mainCanvas.getContext("2d");
      const tempCanvasContext = tempCanvas.getContext("2d");
      const historyCanvasContext = historyCanvas.getContext("2d");

      const mainImageData = mainCanvasContext.getImageData(
        0,
        0,
        mainCanvas.width,
        mainCanvas.height
      );
      const tempImageData = tempCanvasContext.getImageData(
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      );
      const historyImageData = historyCanvasContext.getImageData(
        0,
        0,
        historyCanvas.width,
        historyCanvas.height
      );

      tempCanvas.width = W;
      tempCanvas.height = H;
      historyCanvas.width = W;
      historyCanvas.height = H;
      mainCanvas.width = W;
      mainCanvas.height = H;

      mainCanvasContext.fillStyle = "#FFFFFF";
      mainCanvasContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
      tempCanvasContext.lineWidth = 30;
      tempCanvasContext.lineJoin = "round";
      tempCanvasContext.lineCap = "round";
      tempCanvasContext.strokeStyle = "#0019FF";

      mainCanvasContext.putImageData(mainImageData, 0, 0);
      tempCanvasContext.putImageData(tempImageData, 0, 0);
      historyCanvasContext.putImageData(historyImageData, 0, 0);
    }

    // Set initial dimensions
    updateDimensions();

    const tempCanvasContext = tempCanvas.getContext("2d");
    const historyCanvasContext = historyCanvas.getContext("2d");
    const mainCanvasContext = mainCanvas.getContext("2d");

    mainCanvasContext.fillStyle = "#FFFFFF";
    mainCanvasContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

    tempCanvasContext.lineWidth = 30;
    tempCanvasContext.lineJoin = "round";
    tempCanvasContext.lineCap = "round";
    tempCanvasContext.strokeStyle = "#0019FF";

    function handlePointerDown(e) {
      points.push({
        x: e.clientX === 0 ? 0 : e.clientX || e.touches[0].clientX,
        y: e.clientY === 0 ? 0 : e.clientY || e.touches[0].clientY,
      });
      isDrawing = true;
      console.log("handlePointerDown", points, isDrawing);
    }

    function handlePointerUp(e) {
      isDrawing = false;
      points = [];
      historyCanvasContext.globalCompositeOperation = "source-over";
      historyCanvasContext.drawImage(tempCanvas, 0, 0);
      console.log("handlePointerUp", points, isDrawing);
    }

    //logo follows cursor
    const setLogoPositionToCursor = (event) => {
      const logo = logoRef.current;

      // Check if the event is a touch event
      let clientX, clientY;
      if (event.touches) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      if (logo && isLogoFollowing) {
        logo.style.left = `${clientX - logoImageRef.current.width / 2}px`;
        logo.style.top = `${clientY - logoImageRef.current.height / 2}px`;
      }
    };

    function handlePointerMove(e) {
      if (!canDraw) return;
      if (!isDrawing) return;

      // If the user starts scratching, hide the modal and update the userHasScrapped state.
      setShowModal(false);
      // setUserHasScrapped(true);

      let clientX, clientY;
      if (e.touches) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }

      points.push({ x: clientX, y: clientY });

      let p1 = points[0];
      let p2 = points[1];

      tempCanvasContext.clearRect(0, 0, W, H);
      tempCanvasContext.beginPath();
      tempCanvasContext.moveTo(p2.x, p2.y);

      for (let i = 1; i < points.length; i++) {
        const midPoint = {
          x: p1.x + (p2.x - p1.x) / 2,
          y: p1.y + (p2.y - p1.y) / 2,
        };
        tempCanvasContext.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
        p1 = points[i];
        p2 = points[i + 1];
        if (!p2) break; // Break the loop if p2 is undefined
      }

      tempCanvasContext.lineTo(p1.x, p1.y);
      tempCanvasContext.stroke();

      mainCanvasContext.globalCompositeOperation = "source-over";
      mainCanvasContext.clearRect(0, 0, W, H);
      mainCanvasContext.fillRect(0, 0, W, H);
      mainCanvasContext.globalCompositeOperation = "destination-out";
      mainCanvasContext.drawImage(historyCanvas, 0, 0);
      mainCanvasContext.drawImage(tempCanvas, 0, 0);
      console.log("handlePointerMove", points, isDrawing);
    }

    function handleLogoMouseDown(e) {
      console.log("handleLogoMouseDown");
      isLogoFollowing = true;
      // Hide the cursor
      logoRef.current.style.cursor = "none";
    }

    function handleLogoMouseUp(e) {
      console.log("handleLogoMouseUp");
      isLogoFollowing = false;
      // Show the cursor
      logo.style.cursor = "auto";
    }

    const handleMouseMove = (event) => {
      setLogoPositionToCursor(event);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleMouseMove);
    window.addEventListener("resize", function (event) {
      // Recalculate positions or dimensions here
    });

    // Add event listeners
    ["mousedown", "touchstart"].forEach((event) => {
      logo.addEventListener(event, handleLogoMouseDown);
    });

    ["mouseup", "touchend"].forEach((event) => {
      logo.addEventListener(event, handleLogoMouseUp);
    });

    ["mousemove", "touchmove"].forEach((event) => {
      document.addEventListener(event, handleMouseMove);
    });

    // Add event listeners
    ["mousedown", "touchstart"].forEach((event) => {
      document.addEventListener(event, handlePointerDown);
      logo.addEventListener(event, handleLogoMouseDown);
      logo.addEventListener("mouseup", handleLogoMouseUp);
    });

    ["mouseup", "touchend"].forEach((event) => {
      document.addEventListener(event, handlePointerUp);
    });

    ["mousemove", "touchmove"].forEach((event) => {
      document.addEventListener(event, handlePointerMove);
    });
    window.addEventListener("resize", updateDimensions);

    // Remove event listeners on cleanup
    return () => {
      ["mousedown", "touchstart"].forEach((event) => {
        document.removeEventListener(event, handlePointerDown);
        logo.removeEventListener(event, handleLogoMouseDown);
      });

      ["mouseup", "touchend"].forEach((event) => {
        document.removeEventListener(event, handlePointerUp);
      });

      ["mousemove", "touchmove"].forEach((event) => {
        document.removeEventListener(event, handlePointerMove);
      });
      window.removeEventListener("resize", updateDimensions);

      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [canDraw]);

  return (
    <div className="app-container">
      {/* White overlay - visible if bubbles are visible */}
      {bubblesVisible && <div className="white-overlay"></div>}

      <div
        ref={logoRef}
        className="logo"
        style={{ position: "fixed", zIndex: 9999 }}
      ></div>
      <div
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          zIndex: 9999,
        }}
      >
        {/* Render the clear screen button only if bubbles are not visible */}
        {!bubblesVisible && (
          <button
            className="clear-button"
            onClick={() => {
              clearCanvas(mainCanvasRef);
              clearCanvas(historyCanvasRef);
              clearCanvas(tempCanvasRef);
            }}
          >
            <img
              src={clearScreenImage}
              alt="Clear Screen"
              className="clear-button-img"
            />
          </button>
        )}

 
      </div>

      <div className="logo-container">
        <img
          src="logo.svg"
          alt="Logo"
          className="logo"
          onClick={handleLogoClick}
        />
      </div>

      {bubblesVisible && (
        <div className="main-content">
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
              <p>Don't</p> <p>burst</p> <p>your</p> <p>bubble.</p> <p>Learn</p>
              <p>more</p><p>about</p> <p>Typo</p>
              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="tokenThumbnail.png"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">*Typo.mp4</span>
                </div>
              </button>
              <p>Want</p> <p>to</p> <p>join</p> <p>the</p> <p>waitlist</p>{" "}
              <p>????</p>
              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={TokenImage} alt="Token" />
                  <span className="tokenText">join waitlist</span>
                </div>
              </button>
            </div>
            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <motion.video
                  width="560"
                  height="300"
                  src="https://typowebsitevideo.s3.amazonaws.com/This+is+Typo+_+Final+Cut.mov"
                  poster="https://typowebsitevideo.s3.amazonaws.com/typoVideoThumbnail.png"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "101%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}
              {selectedToken1 === "token2" && (
                <div className="form">
                  {isSubmitted ? ( // Added this line
                    <p className="submitMessage">We'll be in touch :)</p>
                  ) : (
                    <>
                      <div className="formTitle">
                        <h2>Sign up for our beta</h2>
                      </div>
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="container">
                          <select
                            className="country-select"
                            onChange={handleCountryCodeChange}
                            value={countryCode}
                          >
                            <option value="+1US">🇺🇸 +1</option>
                            <option value="+1CA">🇨🇦 +1</option>
                            <option value="+44">🇬🇧 +44</option>
                          </select>

                          <input
                            placeholder="Phone number"
                            type="tel"
                            name="field2"
                            required
                            value={field2}
                            onChange={handleChangeNumber}
                          />
                        </div>

                        <div className="container">
                          <input
                            placeholder="Your full name"
                            type="textarea"
                            name="field1"
                            required
                            value={field1}
                            onChange={handleField1Change}
                          />
                        </div>
                        <div className="container">
                          <input
                            type="submit"
                            className="submit"
                            value="Submit"
                          />
                        </div>
                      </form>
                      {errorMessage ? (
                        <span className="error-message">{errorMessage}</span>
                      ) : (
                        <span className="formInfo">
                          We won't call or share your number
                        </span>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Bubble 2 */}
          <div className="bubble">
            <div className="bubble-top">
              <p>Where</p> <p>to</p> <p>find</p> <p>us:</p>
              <button
                onClick={() => handleTokenClick("token1", 2)}
                className={`token ${
                  selectedToken2 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={LinkImage1} alt="Token" />
                  <span className="tokenText">instagram.com</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token2", 2)}
                className={`token ${
                  selectedToken2 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={LinkImage2} alt="Token" />
                  <span className="tokenText">x.com</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token3", 2)}
                className={`token ${
                  selectedToken2 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={LinkImage3} alt="Token" />
                  <span className="tokenText">youtube.com</span>
                </div>
              </button>
            </div>
            <div className="bubble-bottom">
              {selectedToken2 === "token1" && (
                <div className="token-link">
                  <div className="link-image-container">
                    <img
                      src={instagramLogo}
                      className="link-image"
                      alt="Instagram Logo"
                    />
                  </div>
                  <div className="link-text-container">
                    <p className="link-text">Typo - Instagram.com</p>
                  </div>
                  <div className="link-button-container">
                    <a
                      className="link-button"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/tyyyyyyyyyyypo/"
                    >
                      Visit Link
                    </a>
                  </div>
                </div>
              )}
              {selectedToken2 === "token2" && (
                <div className="token-link">
                  <div className="link-image-container">
                    <img src={xLogo} className="link-image" alt="X Logo" />
                  </div>
                  <div className="link-text-container">
                    <p className="link-text">Typo - X.com</p>
                  </div>
                  <div className="link-button-container">
                    <a
                      className="link-button"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://x.com/tyyyyyyyyyyypo"
                    >
                      Visit Link
                    </a>
                  </div>
                </div>
              )}
              {selectedToken2 === "token3" && (
                <div className="token-link">
                  <div className="link-image-container">
                    <img
                      src= {youtubeLogo}
                      className="link-image"
                      alt="Youtube Logo"
                    />
                  </div>
                  <div className="link-text-container">
                    <p className="link-text">Typo - Youtube.com</p>
                  </div>
                  <div className="link-button-container">
                    <a
                      className="link-button"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/@tyyyyyyyyyyypo"
                    >
                      Visit Link
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
       {/* Render the spacer only if bubbles are not visible */}
       {!bubblesVisible && <div className="spacer"></div>}
      <div className="footer">© Typo</div>

      <canvas className="main-canvas" ref={mainCanvasRef} />
      <canvas className="history-canvas" ref={historyCanvasRef} />
      <canvas className="temp-canvas" ref={tempCanvasRef} />
      {showModal && <Modal />}
      

    </div>
  );
}

export default ScratchCanvas;
