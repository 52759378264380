import React, { useRef, useState } from "react";
import styles from "./AudioBox.module.css";
import playIcon from "./playButton.svg";
import pauseIcon from "./pauseButton.svg";


function AudioBox({ songTitle, fileSize, audioSrc }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
  
    const handlePlayPause = () => {
      if (isPlaying) {
          audioRef.current.pause();
      } else {
          audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };

    return (
        <div className={styles.audioBox}>
          <div className={styles.titleFrame}>
            <div className={styles.title}>{songTitle}</div>
            <div className={styles.fileSize}>{fileSize}</div>
          </div>
    
          <button className={styles.playButton} onClick={handlePlayPause}>
    <img src={isPlaying ? pauseIcon : playIcon} alt="Play/Pause Icon" />
</button>

    
          <audio ref={audioRef} src={audioSrc} />
        </div>
      );
    }
    
    export default AudioBox;
