import React, { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconSVG from "./IconSVG.svg";
import PreviewBox from "./PreviewBox";
import Aos from "aos";
import 'aos/dist/aos.css';

function Home() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const navigate = useNavigate();
  const handleJoinWaitlist = () => {
    navigate("/waitlist");
  };

  const handleWorkWithUs = () => {
    navigate("/jobs");
  };

  useEffect(() => {
    async function loadSvg() {}
    loadSvg();
    Aos.init({
      duration: 800
      // You can also pass in configurations here
    });

  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="page-content">
      <div className="app-container">
        {
          <div className="main-content">
            {/* Bubble 1 */}

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>Messaging</p>
                <p>apps</p>
                <p>haven't</p>
                <p>changed</p>
                <p>in</p>
                <p>10</p>
                <p>years.</p>
                <p>We</p>
                <p>used</p>
                <p>MSN</p>
                <p>and</p>
                <p>AIM</p>
                <p>when</p>
                <p>we</p>
                <p>were</p>
                <p>kids,</p>
                <p>and</p>
                <p>that's</p>
                <p>the</p>
                <p>last</p>
                <p>time</p>
                <p>chat</p>
                <p>felt</p>
                <p>fun</p>
              </div>

              <div className="bubble-bottom">
                {/* You can put content here */}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>Meanwhile,</p>
                <p>creative</p>
                <p>software</p>
                <p>has</p>
                <p>become</p>
                <p>more</p>
                <p>and</p>
                <p>more</p>
                <p>mobile.</p>
                <p>We</p>
                <p>are</p>
                <p>swapping</p>
                <p>files,</p>
                <p>sharing</p>
                <p>links,</p>
                <p>and</p>
                <p>making</p>
                <p>media</p>
                <p>all</p>
                <p>the</p>
                <p>time</p>
              </div>

              <div className="bubble-bottom">
                {/* You can put content here */}
              </div>
            </div>
            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>The</p>
                <p>messenger</p>
                <p>is</p>
                <p>the</p>
                <p>killer</p>
                <p>mobile</p>
                <p>app,</p>
                <p>and</p>
                <p>we</p>
                <p>are</p>
                <p>reimagining</p>
                <p>it</p>
                <p>as</p>
                <p>the</p>
                <p>ultimate</p>
                <p>creative</p>
                <p>app</p>
              </div>

              <div className="bubble-bottom">
                {/* You can put content here */}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>Typo</p> <p>is</p> <p>communication</p> <p>designed</p>{" "}
                <p>for</p> <p>creation</p>
              </div>

              <div className="bubble-bottom">
                {/* You can put content here */}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>If</p>
                <p>you</p>
                <p>use</p>
                <p>and</p>
                <p>love:</p>
                <button
                  onClick={() => handleTokenClick("token1", 1)}
                  className={`token ${
                    selectedToken1 === "token1" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Arc.net</span>
                  </div>
                </button>

                <button
                  onClick={() => handleTokenClick("token2", 1)}
                  className={`token ${
                    selectedToken1 === "token2" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Are.na</span>
                  </div>
                </button>

                <button
                  onClick={() => handleTokenClick("token3", 1)}
                  className={`token ${
                    selectedToken1 === "token3" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Notion.so</span>
                  </div>
                </button>

                <button
                  onClick={() => handleTokenClick("token4", 1)}
                  className={`token ${
                    selectedToken1 === "token4" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Figma.com</span>
                  </div>
                </button>
              </div>

              <div className="bubble-bottom">
                {selectedToken1 === "token1" && (
                  <PreviewBox
                    imageURL="https://arc.net/og.png"
                    logoURL="https://arc.net/favicon.png"
                    ItemURL="https://arc.net"
                    websiteName="Arc from the Browser Company"
                    description="Experience a calmer, more personal internet in this browser designed for you. Let go of the clicks, the clutter, the distractions with the Arc browser."

                    // "https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf"
                  />
                )}

                {selectedToken1 === "token2" && (
                  <PreviewBox
                    logoURL="https://d2hp0ptr16qg89.cloudfront.net/assets/127302a/touch-icon-iphone-retina.png"
                    ItemURL="https://are.na"
                    websiteName="Are.na"
                    description="Are.na is a platform for connecting ideas and building knowledge."
                  />
                )}

                {selectedToken1 === "token3" && (
                  <PreviewBox
                    imageURL="https://www.notion.so/front-static/meta/default.png"
                    logoURL="https://www.notion.so/front-static/favicon.ico"
                    ItemURL="https://www.notion.so/product"
                    websiteName="Your connected workspace for wiki, docs & projects"
                    description="A new tool that blends your everyday work apps into one. It's the all-in-one workspace for you and your team."

                    // "https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf"
                  />
                )}

                {selectedToken1 === "token4" && (
                  <PreviewBox
                    imageURL="https://cdn.sanity.io/images/599r6htc/localized/a9afeb156fd9d624032026ba5dded124f3265a42-2400x1260.png?w=1200&q=70&fit=max&auto=format"
                    logoURL="https://static.figma.com/app/icon/1/favicon.svg"
                    ItemURL="https://figma.com"
                    websiteName="Figma: The Collaborative Interface Design Tool"
                    description="Figma is the leading collaborative design tool for building meaningful products. Seamlessly design, prototype, develop, and collect feedback in a single platform."

                    // "https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf"
                  />
                )}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>Then</p>
                <p>you</p>
                <p>will</p>
                <p class="bold-italic">love</p>
                <p>Typo</p>
              </div>
            </div>
          </div>
        }

        <div className="bottom-bar">
          <div className="buttons-frame">
            <button onClick={handleJoinWaitlist} className="join-waitlist-button">
              Join Waitlist
            </button>
            <button onClick={handleWorkWithUs} className="work-with-us-button">
              Work With Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
