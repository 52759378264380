import React, { createContext, useState, useContext } from 'react';

const NavbarContext = createContext();

export const useNavbarContext = () => useContext(NavbarContext);

export const NavbarProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 9;

  const goToNextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <NavbarContext.Provider value={{ currentStep, totalSteps, goToNextStep, goToPreviousStep }}>
      {children}
    </NavbarContext.Provider>
  );
};
