import React from "react";
import styles from "./PreviewBox.module.css";

function PreviewBox({
  imageURL,
  logoURL = "https://typowebsitevideo.s3.amazonaws.com/partiful_logo.webp",
  ItemURL = "https://partiful.com/e/DGkuOMJvfQtB63hh9B4w",
  websiteName = "RSVP to Studio.day | Partiful joy to the world",
  description = "What is Typo? <br/> Who is Typo?",
}) {
  return (
    <div className={styles.previewBox}>
      <div className={styles.frame}>
        <div className={styles.frame1}>
          {/* Logo */}
          <div
            className={styles.logoContainer}
            style={{
              backgroundImage: `url(${logoURL})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>

          {/* Website Name */}
          <div className={styles.websiteName}> {websiteName} </div>
        </div>

        {/* Visit Link Button */}
        <a
          href={ItemURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <button className={styles.visitLinkButton}>Visit Link</button>
        </a>
      </div>

      <div className={styles.textBox} dangerouslySetInnerHTML={{__html: description}}></div>
      
      {/* Conditionally render image */}
      {imageURL && (
        <a href={ItemURL} className={styles.thumbnailLink} target="_blank" rel="noopener noreferrer">
          <img
            src={imageURL}
            alt={`${websiteName} Thumbnail`}
            className={styles.thumbnailImage}
          />
        </a>
      )}
    </div>
  );
}

export default PreviewBox;
