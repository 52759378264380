import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import AudioBox from "./AudioBox";

function Page7() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [selectedToken3, setSelectedToken3] = useState("token1");
  const [WavIcon1, setWavIcon1] = useState(null);
  const [WavIcon2, setWavIcon2] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let wavImage1;
      if (selectedToken2 === "token2") {
        wavImage1 = wavBlack;
      } else {
        wavImage1 = wavBlack;
      }
      setWavIcon1(wavImage1);

      let wavImage2;
      if (selectedToken3 === "token4") {
        wavImage2 = wavBlack;
      } else {
        wavImage2 = wavBlack;
      }
      setWavIcon2(wavImage2);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2, selectedToken3]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else if (bubble === 2) {
      setSelectedToken2(token);
    } else if (bubble === 3) {
      setSelectedToken3(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">
            We failed at pitching. And failed again.
          </h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
              <p>The</p>
              <p>first</p>
              <p>time</p>
              <p>we</p>
              <p>applied</p>
              <p>to</p>
              <p>YC,</p>
              <p>we</p>
              <p>applied</p>
              <p>as</p>
              <p>a</p>
              <p>non-profit</p>
              <p>—</p>
              <p>unsurprisingly,</p>
              <p>we</p>
              <p>didn’t</p>
              <p>get</p>
              <p>an</p>
              <p>interview.</p>
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>The</p>
              <p>second</p>
              <p>time</p>
              <p>(July)</p>
              <p>we</p>
              <p>did</p>
              <p>get</p>
              <p>an</p>
              <p>interview,</p>
              <p>but</p>
              <p>the</p>
              <p>partners</p>
              <p>hated</p>
              <p>our</p>
              <p>idea.</p>
              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Rejection.webp"
                      alt="YC Rejection"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">YC Rejection</span>
                </div>
              </button>
              <p>They</p>
              <p>told</p>
              <p>us</p>
              <p>it</p>
              <p>was</p>
              <p>a</p>
              <p>feature,</p>
              <p>not</p>
              <p>an</p>
              <p>app</p>
              <p>—</p>
              <p>and</p>
              <p>told</p>
              <p>us</p>
              <p>they</p>
              <p>might</p>
              <p>fund</p>
              <p>us</p>
              <p>if</p>
              <p>we</p>
              <p>copied</p>
              <p>community.com</p>
              <p>with</p>
              <p>an</p>
              <p>SMS</p>
              <p>newsletter</p>
              <p>service</p>
              <p>for</p>
              <p>celebrities.</p>
              <p>We</p>
              <p>said</p>
              <p>no.</p>
            </div>

            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Rejection.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}
            </div>
            </div>

            <div className="bubble">
              <div className="bubble-top">
                <p>Then</p>
                <p>we</p>
                <p>applied</p>
                <p>for</p>
                <p>an</p>
                <p>incubator</p>
                <p>called</p>
                <p>Hyper.</p>
                <p>Sahil</p>
                <p>thought</p>
                <p>we</p>
                <p>were</p>
                <p>a</p>
                <p>shoe-in,</p>
                <p>as</p>
                <p>we</p>
                <p>had</p>
                <p>a</p>
                <p>warm</p>
                <p>intro.</p>
                <p>But</p>
                <p>this</p>
                <p>was</p>
                <p>the</p>
                <p>worst</p>
                <p>experience</p>
                <p>of</p>
                <p>them</p>
                <p>all</p>
                <p>—</p>
                <p>we</p>
                <p>completely</p>
                <p>flopped.</p>

                <button
                  onClick={() => handleTokenClick("token2", 2)}
                  className={`token ${
                    selectedToken2 === "token2" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <div className="thumbnail">
                      <img
                        src={WavIcon1}
                        alt="Hyper Interviwew"
                        className="thumbnailImg"
                      ></img>
                    </div>

                    <span className="tokenText">hyper interview.mp3.</span>
                  </div>
                </button>
                <p>They</p>
                <p>outright</p>
                <p>rejected</p>
                <p>our</p>
                <p>value</p>
                <p>prop</p>
                <p>and</p>
                <p>said</p>
                <p>screenshots</p>
                <p>worked</p>
                <p>perfectly</p>
                <p>fine.</p>
                <p>Sahil</p>
                <p>spent</p>
                <p>valuable</p>
                <p>minutes</p>
                <p>mistakenly</p>
                <p>trying</p>
                <p>to</p>
                <p>convince</p>
                <p>them</p>
                <p>they</p>
                <p>were</p>
                <p>wrong.</p>
                <p>We</p>
                <p>needed</p>
                <p>the</p>
                <p>money,</p>
                <p>and</p>
                <p>he</p>
                <p>had</p>
                <p>completely</p>
                <p>fumbled</p>
                <p>the</p>
                <p>bag.</p>
                <button
                  onClick={() => handleTokenClick("token3", 2)}
                  className={`token ${
                    selectedToken2 === "token3" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <div className="thumbnail">
                      <img
                        src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_Oct_12_from_Notion.webp"
                        alt="Hyper Rejection"
                        className="thumbnailImg"
                      ></img>
                    </div>

                    <span className="tokenText">Hyper Rejection</span>
                  </div>
                </button>
              </div>

              <div className="bubble-bottom">
                {selectedToken2 === "token2" && (
                  <AudioBox
                    songTitle="Hyper Interview Recording.mp3"
                    fileSize="218 KB"
                    audioSrc="https://typowebsitevideo.s3.amazonaws.com/hyper+interview+copy.m4a"
                  />
                )}
                {selectedToken2 === "token3" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_Oct_12_from_Notion.webp"
                    alt="Invite"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}
              </div>
            </div>

            <div className="bubble">
              <div className="bubble-top">
              <p>In</p>
<p>October</p>
<p>Sahil</p>
<p>was</p>
<p>in</p>
<p>London,</p>
<p>and</p>
<p>he</p>
<p>managed</p>
<p>to</p>
<p>catch</p>
<p>Daz</p>
<p>for</p>
<p>five</p>
<p>minutes</p>
<p>at</p>
<p>a</p>
<p>hotel</p>
<p>—</p>
<p>he</p>
<p>was</p>
<p>on</p>
<p>his</p>
<p>way</p>
<p>to</p>
<p>a</p>
<p>big</p>
<p>boxing</p>
<p>fight.</p>
<p>Sahil</p>
<p>was</p>
<p>,</p>
<p>so</p>
<p>was</p>
<p>desperate,</p>
<p>so</p>
<p>he</p>
<p>jumped</p>
<p>into</p>
<p>the</p>
<p>car</p>
<p>with</p>
<p>him</p>
<p>and</p>
<p>pitched</p>
<p>him</p>
<p>and</p>
<p>three</p>
<p>massive</p>
<p>boxers</p>
<p>at</p>
<p>the</p>
<p>back</p>
<p>of</p>
<p>a</p>
<p>black</p>
<p>taxi.</p>
<p>It</p>
<p>was</p>
<p>an</p>
<p>insane</p>
<p>experience.</p>
<p>The</p>
<p>boxers</p>
<p>loved</p>
<p>it,</p>
<p>but</p>
<p>Daz</p>
<p>wasn’t</p>
<p>ready</p>
<p>to</p>
<p>write</p>
<p>the</p>
<p>check.</p>
<button
                  onClick={() => handleTokenClick("token4", 3)}
                  className={`token ${
                    selectedToken3 === "token4" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <div className="thumbnail">
                      <img
                        src={WavIcon2}
                        alt="Daz and Boxers"
                        className="thumbnailImg"
                      ></img>
                    </div>

                    <span className="tokenText">Daz in cab with boxers.</span>
                  </div>
                </button>



              </div>

              <div className="bubble-bottom">
                {selectedToken3 === "token4" && (
                  <AudioBox
                    songTitle="Daz in cab with boxers.mp3"
                    fileSize="138 KB"
                    audioSrc="https://typowebsitevideo.s3.amazonaws.com/daz+in+cab+w+boxers.m4a"
                  />
                )}
              </div>
            </div>
          </div>
        
      }
    </div>
  );
}

export default Page7;
