import React, { useEffect, useState, useLayoutEffect } from "react";



function Page11() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");


  useEffect(() => {
    async function loadSvg() {

    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (

    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">We formed a team and work actually started getting done.</h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
            <p>We</p>
<p>were</p>
<p>finally</p>
<p>set</p>
<p>and</p>
<p>ready</p>
<p>to</p>
<p>make</p>
<p>progress.</p>
<p>Bardi</p>
<p>had</p>
<p>joined</p>
<p>the</p>
<p>team,</p>
<p>which</p>
<p>had</p>
<p>been</p>
<p>four-years-in</p>
<p>the</p>
<p>making;</p>
<p>Eli</p>
<p>had</p>
<p>recovered</p>
<p>from</p>
<p>two</p>
<p>concussions;</p>
<p>and</p>
<p>we’d</p>
<p>recruited</p>
<p>two</p>
<p>incredible</p>
<p>founding</p>
<p>engineers,</p>
<p>Maxwell</p>
<p>and</p>
<p>Kanda,</p>
<p>to</p>
<p>help</p>
<p>us</p>
<p>build</p>
<p>the</p>
<p>product.</p>
<p>This</p>
<p>is</p>
<p>the</p>
<p>first</p>
<p>time</p>
<p>in</p>
<p>the</p>
<p>history</p>
<p>of</p>
<p>the</p>
<p>company</p>
<p>that</p>
<p>we</p>
<p>actually</p>
<p>felt</p>
<p>like</p>
<p>anything</p>
<p>close</p>
<p>to</p>
<p>a</p>
<p>company.</p>
<p>It</p>
<p>was</p>
<p>a</p>
<p>great</p>
<p>feeling.</p>






              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/IMG_1623.webp"
                      alt="photo1"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo1</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/IMG_2432.webp"
                      alt="photo2"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo2</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/IMG_2433.webp"
                      alt="photo3"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo3</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/IMG_6343_(1).webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo4</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token5", 1)}
                className={`token ${
                  selectedToken1 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Micro_Site_Needs_IMG_2501.webp"
                      alt="photo5"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">photo5</span>
                </div>
              </button>


            </div>

            <div className="bubble-bottom">


              {selectedToken1 === "token1" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_1623.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token2" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_2432.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token3" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_2433.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token4" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/IMG_6343_(1).webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken1 === "token5" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Micro_Site_Needs_IMG_2501.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

            </div>
          </div>
        </div>
      }
    </div>
    
  );
}

export default Page11;
