import React, { useEffect, useState, useLayoutEffect } from "react";
import { motion } from "framer-motion";


function Page9() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");


  useEffect(() => {
    async function loadSvg() {

    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">We finally convinced someone to give us money.</h1>
          {/* Bubble 1 */}

  
          
          <div className="bubble">
            <div className="bubble-top">
            <p>Sahil</p>
<p>turned</p>
<p>to</p>
<p>two</p>
<p>friends:</p>
<p>Jake</p>
<p>Sylvestre</p>
<p>and</p>
<p>Jesse</p>
<p>Kay.</p>
<p>He</p>
<p>had</p>
<p>never</p>
<p>asked</p>
<p>friends</p>
<p>for</p>
<p>money</p>
<p>before</p>
<p>—</p>
<p>we</p>
<p>knew</p>
<p>this</p>
<p>was</p>
<p>an</p>
<p>investment</p>
<p>opportunity,</p>
<p>but</p>
<p>it</p>
<p>still</p>
<p>felt</p>
<p>like</p>
<p>asking</p>
<p>friends</p>
<p>for</p>
<p>money.</p>
<p>Jesse</p>
<p>even</p>
<p>asked</p>
<p>his</p>
<p>parents</p>
<p>to</p>
<p>borrow</p>
<p>half</p>
<p>of</p>
<p>the</p>
<p>money</p>
<p>so</p>
<p>that</p>
<p>he</p>
<p>could</p>
<p>fund</p>
<p>us.</p>
<p>We’ll</p>
<p>never</p>
<p>forget</p>
<p>it.</p>

            </div>
            
            <div className="bubble-bottom">
              {/* You can put content here */}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
            <p>A</p>
<p>few</p>
<p>months</p>
<p>later,</p>
<p>David</p>
<p>and</p>
<p>Sahil</p>
<p>learned</p>
<p>that</p>
<p>Eli’s</p>
<p>grandad</p>
<p>was</p>
<p>actually</p>
<p>the</p>
<p>founder</p>
<p>of</p>
<p>one</p>
<p>of</p>
<p>the</p>
<p>oldest</p>
<p>VC</p>
<p>firms</p>
<p>in</p>
<p>the</p>
<p>world.</p>
<p>So,</p>
<p>we</p>
<p>asked</p>
<p>him</p>
<p>for</p>
<p>a</p>
<p>small</p>
<p>check</p>
<p>to</p>
<p>get</p>
<p>ourselves</p>
<p>a</p>
<p>tiny</p>
<p>wework</p>
<p>office.</p>
<p>We</p>
<p>met</p>
<p>him</p>
<p>at</p>
<p>a</p>
<p>fancy</p>
<p>hotel</p>
<p>in</p>
<p>Cambridge</p>
<p>and</p>
<p>he</p>
<p>gave</p>
<p>us</p>
<p>enough</p>
<p>to</p>
<p>pay</p>
<p>the</p>
<p>bills</p>
<p>for</p>
<p>a</p>
<p>few</p>
<p>months.</p>
<p>This</p>
<p>was</p>
<p>also</p>
<p>when</p>
<p>Sahil</p>
<p>began</p>
<p>to</p>
<p>think</p>
<p>we</p>
<p>should</p>
<p>pitch</p>
<p>ourselves</p>
<p>as</p>
<p>a</p>
<p>crypto</p>
<p>company.</p>

            </div>
            
            <div className="bubble-bottom">
              {/* You can put content here */}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
            <p>Soon,</p>
<p>we</p>
<p>were</p>
<p>close</p>
<p>to</p>
<p>running</p>
<p>out</p>
<p>of</p>
<p>money</p>
<p>again.</p>
<p>In</p>
<p>March,</p>
<p>Jake</p>
<p>told</p>
<p>Sahil</p>
<p>to</p>
<p>come</p>
<p>to</p>
<p>ETHDenver</p>
<p>to</p>
<p>raise</p>
<p>a</p>
<p>round.</p>
<p>He</p>
<p>made</p>
<p>a</p>
<p>last</p>
<p>minute</p>
<p>decision</p>
<p>to</p>
<p>go,</p>
<p>but</p>

<p>couldn’t</p>
<p>afford</p>
<p>a</p>
<p>hotel</p>
<p>so,</p>
<p>Sahil</p>
<p>slept</p>
<p>in</p>
<p>his</p>
<p>bed</p>
<p>while</p>
<p>he</p>
<p>was</p>
<p>bug-squashing!</p>
<p>He’d</p>
<p>never</p>
<p>been</p>
<p>to</p>
<p>a</p>
<p>crypto</p>
<p>conference</p>
<p>before.</p>

            </div>
            
            <div className="bubble-bottom">
              {/* You can put content here */}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
            <p>Jake</p>
<p>and</p>
<p>Sahil</p>
<p>were</p>
<p>sat</p>
<p>downstairs</p>
<p>at</p>
<p>the</p>
<p>Magnolia</p>
<p>hotel</p>
<p>with</p>
<p>two</p>
<p>friends,</p>
<p>and</p>
<p>he</p>
<p>gave</p>
<p>him</p>
<p>a</p>
<p>look</p>
<p>as</p>
<p>if</p>
<p>to</p>
<p>say</p>
<p>“these</p>
<p>are</p>
<p>your</p>
<p>people.”</p>
<p>He</p>
<p>came</p>
<p>home</p>
<p>with</p>
<p>$10k</p>
<p>committed,</p>
<p>and</p>
<p>that</p>
<p>kicked</p>
<p>off</p>
<p>the</p>
<p>round.</p>

            </div>
            
            <div className="bubble-bottom">
              {/* You can put content here */}
            </div>
          </div>


          <div className="bubble">
            <div className="bubble-top">

            <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="  https://typowebsitevideo.s3.amazonaws.com/IMG_1275.webp"
                      alt="Photo1"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Photo1</span>
                </div>
              </button>
             
              <button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/newPhoto4.webp"
                      alt="Photo4"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Photo4</span>
                </div>
              </button>


              <button
                onClick={() => handleTokenClick("token5", 1)}
                className={`token ${
                  selectedToken1 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/VT_David+whiteboarding+on+the+window+of+our+first+office.webp "
                      alt="David whiteboarding"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David whiteboarding</span>
                </div>
              </button>


            </div>

            <div className="bubble-bottom">
            {selectedToken1 === "token1" && (
                <img
                  src=" https://typowebsitevideo.s3.amazonaws.com/IMG_1275.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}


{selectedToken1 === "token4" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/newPhoto4.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}


              {selectedToken1 === "token5" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/David+whiteboarding+on+the+window+of+our+first+office.mov"
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_David+whiteboarding+on+the+window+of+our+first+office.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}



            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page9;
