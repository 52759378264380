import React, { useEffect, useState, useLayoutEffect } from "react";
import { motion } from "framer-motion";



function Page4() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");


  useEffect(() => {
    async function loadSvg() {

    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">YC called us out on our bullshit.</h1>
          {/* Bubble 1 */}

          
          <div className="bubble">
            <div className="bubble-top">
            <p>After</p>
<p>submitting</p>
<p>our</p>
<p>application</p>
<p>to</p>
<p>YC</p>
<p>claiming</p>
<p>we</p>
<p>were</p>
<p>about</p>
<p>to</p>
<p>launch</p>
<p>a</p>
<p>product,</p>
<p>we</p>
<p>were</p>
<p>met</p>
<p>with</p>
<p>this</p>
<button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/YC_called_us_out.webp"
                      alt="YC Screenshot"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">message - YC screenshot.png</span>
                </div>
              </button>









              <p>For</p>
<p>the</p>
<p>first</p>
<p>three</p>
<p>years</p>
<p>of</p>
<p>our</p>
<p>friendships,</p>
<p>I</p>
<p>didn’t</p>
<p>know</p>
<p>David</p>
<p>and</p>
<p>Eli</p>
<p>could</p>
<p>code.</p>
<p>Turns</p>
<p>out</p>
<p>David</p>
<p>was</p>
<p>a</p>
<p>software</p>
<p>engineer</p>
<p>at</p>
<p>16</p>
<p>and</p>
<p>Eli</p>
<p>was</p>
<p>a</p>
<p>national</p>
<p>maths</p>
<p>competitor</p>
<p>in</p>
<p>high</p>
<p>school</p>
<p>🤷‍♂️</p>
<button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/studiodayvideothumbnail.webp "
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Discovery.</span>
                </div>
              </button>
            </div>
            
            <div className="bubble-bottom">
            {selectedToken1 === "token1" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/YC_called_us_out.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

            {selectedToken1 === "token2" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/Discovering+that+we+write+code+(but+we're+creatives).mp4 "
                  poster="https://typowebsitevideo.s3.amazonaws.com/studiodayvideothumbnailxlsmall.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}
            </div>
          </div>


          <div className="bubble">
            <div className="bubble-top">

<p>David</p>
<p>taught</p>
<p>Eli</p>
<p>and</p>
<p>Sahil</p>
<p>how</p>
<p>to</p>
<p>code,</p>
<p>and</p>
<p>then</p>
<p>Eli</p>
<p>taught</p>
<p>Sahil</p>
<p>how</p>
<p>to</p>
<p>code</p>
<p>again.</p>
<button
                onClick={() => handleTokenClick("token3", 2)}
                className={`token ${
                  selectedToken2 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/studiodaythumbnail.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">David teaching Sahil SQL</span>
                </div>
              </button>
<p>Sahil</p>
<p>taught</p>
<p>Sahil</p>
<p>how</p>
<p>to</p>
<p>design,</p>
<p>which</p>
<p>is</p>
<p>why</p>
<p>the</p>
<p>designs</p>
<p>are</p>
<p>this</p>
<p>bad.</p>
<button
                onClick={() => handleTokenClick("token4", 2)}
                className={`token ${
                  selectedToken2 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/design1.webp"
                      alt="sketch thumbnail 1"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Sahil's app sketch1.png</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token5", 2)}
                className={`token ${
                  selectedToken2 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/design1.webp"
                      alt="Sketch thumbnail 2"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Sahil's app sketch2..png</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token6", 2)}
                className={`token ${
                  selectedToken2 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/design1.webp"
                      alt="Sketch Thumbnail 3"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Sahil's app sketch3.png</span>
                </div>
              </button>

              
              
         
            </div>

            <div className="bubble-bottom">





              {selectedToken2 === "token3" && (
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/David+teaching+Sahil+how+to+code+(SQL).mp4 "
                  poster="https://typowebsitevideo.s3.amazonaws.com/studiodayvideothumbnailxlsmall.webp"
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              )}

{selectedToken2 === "token4" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/design1.webp"
                  alt="Sahil sketch 1"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken2 === "token5" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/design2.webp"
                  alt="Sahil sketch 2"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

{selectedToken2 === "token6" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/design3.webp"
                  alt="Sahil sketch 3"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

          

            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page4;
