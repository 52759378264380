import React, { useEffect, useState, useLayoutEffect } from "react";
import linkBlack from "./IconSVG.svg";
// import PdfViewer from "./PdfViewer";
import PreviewBox from "./PreviewBox";


function Page10() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [LinkImage1, setLinkImage1] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let svgLink1;
      if (selectedToken1 === "token1") {
        svgLink1 = linkBlack;
      } else {
        svgLink1 = linkBlack;
      }
      setLinkImage1(svgLink1);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">
            Suddenly, everyone wanted to give us money.
          </h1>
          {/* Bubble 1 */}

          <div className="bubble">
            <div className="bubble-top">
              <p>A</p>
              <p>week</p>
              <p>later</p>
              <p>Sahil’s</p>
              <p>plan</p>
              <p>was</p>
              <p>to</p>
              <p>travel</p>
              <p>to</p>
              <p>London,</p>
              <p>catch</p>
              <p>up</p>
              <p>on</p>
              <p>schoolwork,</p>
              <p>surprise</p>
              <p>his</p>
              <p>brother</p>
              <p>for</p>
              <p>his</p>
              <p>birthday</p>
              <p>there,</p>
              <p>and</p>
              <p>return</p>
              <p>to</p>
              <p>NYC.</p>
              <p>Instead,</p>
              <p>he</p>
              <p>took</p>
              <p>a</p>
              <p>zoom</p>
              <p>call</p>
              <p>with</p>
              <p>Gokul</p>
              <p>Rajaram</p>
              <p>from</p>
              <p>the</p>
              <p>hotel</p>
              <p>room</p>
              <p>as</p>
              <p>soon</p>
              <p>as</p>
              <p>he</p>
              <p>got</p>
              <p>there.</p>
              <p>He</p>
              <p>expected</p>
              <p>it</p>
              <p>to</p>
              <p>be</p>
              <p>a</p>
              <p>friendly</p>
              <p>chat,</p>
              <p>but</p>
              <p>Gokul</p>
              <p>committed</p>
              <p>the</p>
              <p>first</p>
              <p>check.</p>
              <p>We</p>
              <p>were</p>
              <p>launched</p>
              <p>into</p>
              <p>the</p>
              <p>raise.</p>
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>Eli</p>
              <p>was</p>
              <p>recovering</p>
              <p>from</p>
              <p>two</p>
              <p>concussions</p>
              <p>at</p>
              <p>the</p>
              <p>time,</p>
              <p>and</p>
              <p>David</p>
              <p>was</p>
              <p>heads</p>
              <p>down</p>
              <p>getting</p>
              <p>our</p>
              <p>new</p>
              <p>wallet-wallet</p>
              <p>messaging</p>
              <p>app</p>
              <p>to</p>
              <p>work.</p>
              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={LinkImage1} alt="memo" />
                  <span className="tokenText">pdf of memo</span>
                </div>
              </button>
              
              <p>The</p>
              <p>raise</p>
              <p>was</p>
              <p>intense.</p>
              <p>Sahil</p>
              <p>was</p>
              <p>still</p>
              <p>enrolled</p>
              <p>in</p>
              <p>school,</p>
              <p>so</p>
              <p>he</p>
              <p>was</p>
              <p>literally</p>
              <p>ducking</p>
              <p>out</p>
              <p>of</p>
              <p>classes</p>
              <p>to</p>
              <p>take</p>
              <p>calls</p>
              <p>with</p>
              <p>investors.</p>
              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Bylines_to_Typo.webp"
                      alt="Calendar 1"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Calendar 1</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Untitled_calendar_days.webp"
                      alt="Calender 2"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Calendar 2</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token5", 1)}
                className={`token ${
                  selectedToken1 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Untitled_calendar_days_(1).webp"
                      alt="Calender 3"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Calendar 3</span>
                </div>
              </button>
              <p>We</p>
              <p>set</p>
              <p>out</p>
              <p>to</p>
              <p>raise</p>
              <p>$1.5m,</p>
              <p>expanded</p>
              <p>that</p>
              <p>to</p>
              <p>$2m,</p>
              <p>and</p>
              <p>ended</p>
              <p>up</p>
              <p>at</p>
              <p>$4m.</p>
              <p>We</p>
              <p>were</p>
              <p>doing</p>
              <p>references</p>
              <p>on</p>
              <p>everyone,</p>
              <p>and</p>
              <p>that</p>
              <p>meant</p>
              <p>triple</p>
              <p>the</p>
              <p>calls.</p>
              <p>By</p>
              <p>the</p>
              <p>end,</p>
              <p>we</p>
              <p>were</p>
              <p>getting</p>
              <p>five</p>
              <p>messages</p>
              <p>a</p>
              <p>day</p>
              <p>from</p>
              <p>investors</p>
              <p>asking</p>
              <p>to</p>
              <p>join</p>
              <p>the</p>
              <p>round.</p>
              {/* <p>We</p>
              <p>still</p>
              <p>laugh</p>
              <p>at</p>
              <p>this</p>
              <p>email</p>
              <p>from</p>
              <p>Balaji,</p>
              <p>who</p>
              <p>committed</p>
              <p>without</p>
              <p>ever</p>
              <p>meeting</p>
              <p>us.</p>
              <button
                onClick={() => handleTokenClick("token6", 1)}
                className={`token ${
                  selectedToken1 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_Oct_17_from_Balaji_email.webp"
                      alt="Balaji Screenshot"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Balaji Screenshot</span>
                </div>
              </button> */}
            </div>

            <div className="bubble-bottom">
              {selectedToken1 === "token1" && <PreviewBox 
              
              imageURL="https://typowebsitevideo.s3.amazonaws.com/Lines_Crypto_Memo+_mage.png"
              logoURL="https://typowebsitevideo.s3.amazonaws.com/pdflogo.png"
              ItemURL="https://typowebsitevideo.s3.amazonaws.com/Lines+55c8391b70ea49efa72d547ca69a94de+(1).pdf"
              websiteName="Lines Crypto Memo"
              description="Bylines is building the missing communications platform for web3..."/>}


              {selectedToken1 === "token3" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Bylines_to_Typo.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken1 === "token4" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Untitled_calendar_days.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken1 === "token5" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Untitled_calendar_days_(1).webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken1 === "token6" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Screenshot_Oct_17_from_Balaji_email.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>We</p>
              <p>don’t</p>
              <p>think</p>
              <p>any</p>
              <p>of</p>
              <p>this</p>
              <p>says</p>
              <p>a</p>
              <p>lot</p>
              <p>about</p>
              <p>us</p>
              <p>—</p>
              <p>we</p>
              <p>simply</p>
              <p>became</p>
              <p>a</p>
              <p>hot</p>
              <p>round,</p>
              <p>and</p>
              <p>that</p>
              <p>changed</p>
              <p>the</p>
              <p>equation.</p>
              <p>Needless</p>
              <p>to</p>
              <p>say,</p>
              <p>We</p>
              <p>learned</p>
              <p>a</p>
              <p>lot</p>
              <p>about</p>
              <p>VC</p>
              <p>and</p>
              <p>how</p>
              <p>that</p>
              <p>crazy</p>
              <p>world</p>
              <p>works.</p>
              {/* <button
                onClick={() => handleTokenClick("token7", 2)}
                className={`token ${
                  selectedToken2 === "token7" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Cory_fomo.webp"
                      alt="Raise Screenshot 1"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Raise Screenshot 1</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token8", 2)}
                className={`token ${
                  selectedToken2 === "token8" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/When_we_were_closing_the_round.webp"
                      alt="Raise Screenshot 2"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Raise Screenshot 2</span>
                </div>
              </button>

             
              <button
                onClick={() => handleTokenClick("token11", 2)}
                className={`token ${
                  selectedToken2 === "token11" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/When_we_were_closing_the_round_(5).webp"
                      alt="Raise Screenshot 5"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Raise Screenshot 5</span>
                </div>
              </button>


              <button
                onClick={() => handleTokenClick("token12", 2)}
                className={`token ${
                  selectedToken2 === "token12" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/When_we_were_closing_the_round_(4).webp"
                      alt="Raise Screenshot 6"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Raise Screenshot 6</span>
                </div>
              </button> */}


             
            </div>

            <div className="bubble-bottom">
              {/* {selectedToken2 === "token7" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Cory_fomo.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}
              {selectedToken2 === "token8" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/When_we_were_closing_the_round.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}



              {selectedToken2 === "token11" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/When_we_were_closing_the_round_(5).webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken2 === "token12" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/When_we_were_closing_the_round_(4).webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )} */}

            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page10;
