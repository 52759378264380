import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './HistoryNavigator.module.css';

function HistoryNavigator({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    // Mapping paths to a sequence to determine next/prev paths
    const paths = [
        "/studioday/from-bylines-to-typo/1",
"/studioday/from-bylines-to-typo/2",
"/studioday/from-bylines-to-typo/3",
"/studioday/from-bylines-to-typo/4",
"/studioday/from-bylines-to-typo/5",
"/studioday/from-bylines-to-typo/6",
"/studioday/from-bylines-to-typo/7",
"/studioday/from-bylines-to-typo/8",
"/studioday/from-bylines-to-typo/9",
"/studioday/from-bylines-to-typo/10",
"/studioday/from-bylines-to-typo/11",
"/studioday/from-bylines-to-typo/12",
"/studioday/from-bylines-to-typo/13",
"/studioday/from-bylines-to-typo/14"

    ];
    const currentIndex = paths.indexOf(location.pathname);

    const handlePrevious = () => {
        if (currentIndex > 0) {
            navigate(paths[currentIndex - 1]);
        }
    };

    const handleNext = () => {
        if (currentIndex < paths.length - 1) {
            navigate(paths[currentIndex + 1]);
        }
    };

    return (
        <div className={styles.pageContainer}>
            {children}

            <div className={styles.bottomBar}>
                <div className={styles.buttonsFrame}>
                    <button 
                        onClick={handlePrevious} 
                        className={styles.previousButton} // Use your button style
                        disabled={currentIndex === 0}
                    >
                        Previous
                    </button>
                    <button 
                        onClick={handleNext} 
                        className={styles.nextButton} // Use your button style
                        disabled={currentIndex === paths.length - 1}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HistoryNavigator;