import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import AudioBox from "./AudioBox";

function Page14() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [WavIcon, setWavIcon] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let wavImage;
      if (selectedToken1 === "token2") {
        wavImage = wavBlack;
      } else {
        wavImage = wavBlack;
      }
      setWavIcon(wavImage);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">Typo is born.</h1>
          {/* Bubble 1 */}

          <div className="bubble">
            <div className="bubble-top">
              <p>I’ll</p>
              <p>keep</p>
              <p>this</p>
              <p>message</p>
              <p>short,</p>
              <p>because</p>
              <p>you’re</p>
              <p>standing</p>
              <p>in</p>
              <p>a</p>
              <p>room</p>
              <p>dedicated</p>
              <p>to</p>
              <p>Typo.</p>
              <p>This</p>
              <p>has</p>
              <p>already</p>
              <p>been</p>
              <p>the</p>
              <p>best</p>
              <p>phase</p>
              <p>of</p>
              <p>the</p>
              <p>company.</p>
              <p>The</p>
              <p>product</p>
              <p>feels</p>
              <p>deeply</p>
              <p>aligned</p>
              <p>with</p>
              <p>who</p>
              <p>we</p>
              <p>are,</p>
              <p>and</p>
              <p>it</p>
              <p>feels</p>
              <p>like</p>
              <p>the</p>
              <p>first</p>
              <p>step</p>
              <p>towards</p>
              <p>a</p>
              <p>special</p>
              <p>future.</p>
              <p>We’re</p>
              <p>in</p>
              <p>a</p>
              <p>new</p>
              <p>space,</p>
              <p>and</p>
              <p>we’re</p>
              <p>joined</p>
              <p>by</p>
              <p>two</p>
              <p>incredible</p>
              <p>people:</p>
              <p>the</p>
              <p>best</p>
              <p>interface</p>
              <p>designer</p>
              <p>in</p>
              <p>the</p>
              <p>world,</p>
              <p>and</p>
              <p>the</p>
              <p>most</p>
              <p>user-empathetic</p>
              <p>human</p>
              <p>in</p>
              <p>the</p>
              <p>world.</p>
              <p>Maxwell</p>
              <p>has</p>
              <p>become</p>
              <p>our</p>
              <p>glorious</p>
              <p>engineering</p>
              <p>wizard,</p>
              <p>and</p>
              <p>we</p>
              <p>spend</p>
              <p>our</p>
              <p>days</p>
              <p>imagining</p>
              <p>the</p>
              <p>future</p>
              <p>of</p>
              <p>creative</p>
              <p>collaboration,</p>
              <p>building</p>
              <p>creative</p>
              <p>tools,</p>
              <p>talking</p>
              <p>to</p>
              <p>people</p>
              <p>who</p>
              <p>create</p>
              <p>stuff</p>
              <p>we</p>
              <p>love,</p>
              <p>and</p>
              <p>teaching</p>
              <p>each</p>
              <p>other</p>
              <p>creative</p>
              <p>skills.</p>
            </div>
          </div>

          <div className="bubble">
            <div className="bubble-top">
              <p>Thank</p>
              <p>you</p>
              <p>for</p>
              <p>being</p>
              <p>here.</p>
              <p>“I’ll</p>
              <p>leave</p>
              <p>you</p>
              <p>with</p>
              <p>this</p>
              <p>moment</p>
              <p>from</p>
              <p>Ayo’s</p>
              <p>family</p>
              <p>room</p>
              <p>in</p>
              <p>London.</p>
              <p>He</p>
              <p>had</p>
              <p>just</p>
              <p>cooked</p>
              <p>the</p>
              <p>whole</p>
              <p>team</p>
              <p>a</p>
              <p>wonderful</p>
              <p>meal,</p>
              <p>and</p>
              <p>we</p>
              <p>were</p>
              <p>huddled</p>
              <p>into</p>
              <p>the</p>
              <p>room</p>
              <p>with</p>
              <p>mini</p>
              <p>air</p>
              <p>conditions</p>
              <p>blaring,</p>
              <p>discussing</p>
              <p>the</p>
              <p>future</p>
              <p>of</p>
              <p>the</p>
              <p>Typo*</p>
              <p>product.</p>

              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/AyosKitchen.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    Ayo cooks for the team in London
                  </span>
                </div>
              </button>

           

              <p>There</p>

              <p>were</p>

              <p>a</p>
              <p>lot</p>
              <p>of</p>
              <p>disagreements,</p>

              <p>and</p>
              <p>the</p>
              <p>stakes</p>
              <p>felt</p>
              <p>extremely</p>
              <p>high</p>
              <p>—</p>
              <p>they</p>
              <p>felt</p>
              <p>so</p>
              <p>high</p>
              <p>because</p>
              <p>we</p>
              <p>all</p>
              <p>care</p>
              <p>so</p>
              <p>much</p>
              <p>about</p>
              <p>this</p>
              <p>tool</p>
              <p>and</p>
              <p>what</p>
              <p>it</p>
              <p>could</p>
              <p>become.</p>
              <p>It</p>
              <p>felt</p>
              <p>like</p>
              <p>a</p>
              <p>turning</p>
              <p>point</p>
              <p>for</p>
              <p>us</p>
              <p>as</p>
              <p>a</p>
              <p>company;</p>
              <p>not</p>
              <p>a</p>
              <p>fresh</p>
              <p>start,</p>
              <p>but</p>
              <p>a</p>
              <p>fresh</p>
              <p>chapter.</p>
              <p>We</p>
              <p>were</p>
              <p>making</p>
              <p>for</p>
              <p>ourselves</p>
              <p>and</p>
              <p>our</p>
              <p>friends.</p>
              <p>And</p>
              <p>here</p>
              <p>we</p>
              <p>are</p>
              <p>now,</p>
              <p>with</p>
              <p>all</p>
              <p>of</p>
              <p>you.”</p>

              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    team chat in Ayo's living room
                  </span>
                </div>
              </button>
            </div>

            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/AyosKitchen.webp"
                  alt="Invite"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken1 === "token2" && (
                <AudioBox
                  songTitle="Ayo on how we win for users"
                  fileSize="411 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/Ayo_House.mp3"
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page14;
