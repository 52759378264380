import React from "react";
import { motion } from "framer-motion";

function Page20() {
  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title"> </h1>
          {/* Bubble 1 */}
          <div className="bubbleVideo">
            <div className="bubble-bottom">
              {
                <motion.video
                  width="560"
                  height="310"
                  src="https://typowebsitevideo.s3.amazonaws.com/_6_expand.mp4
                  "
                  poster="https://typowebsitevideo.s3.amazonaws.com/VT_6_expand.webp"
                  autoPlay
                  playsInline
                  controls
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "104%",
                    borderRadius: "16px",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Your browser does not support the video tag.
                </motion.video>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page20;
