import React, { useEffect, useState, useLayoutEffect } from "react";
import IconSVG from "./IconSVG.svg";
import PreviewBox from "./PreviewBox";
import Aos from "aos";
import 'aos/dist/aos.css';


function Job() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [selectedToken3, setSelectedToken3] = useState("token1");

  useEffect(() => {
    async function loadSvg() {}

    loadSvg();
  }, [selectedToken1, selectedToken2, selectedToken3]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else if (bubble === 2) {
      setSelectedToken2(token);
    } else if (bubble === 3) {
      setSelectedToken3(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

      // Initialize AOS
      Aos.init({
        duration: 800 // or your desired configuration
    });

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="page-content">
      <div className="app-container">
        {
          <div className="main-content">
            {/* Bubble 1 */}


            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>If</p>
                <p>you’re</p>
                <p>excited</p>
                <p>by</p>
                <p>our</p>
                <p>vision</p>
                <p>to</p>
                <p>reshape</p>
                <p>communication</p>
                <p>for</p>
                <p>creation</p>
                <p>then</p>
                <p>we</p>
                <p>want</p>
                <p>to</p>
                <p>hear</p>
                <p>from</p>
                <p>you</p>
              </div>

              <div className="bubble-bottom">
                {/* You can put content here */}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>We</p>
                <p>work</p>
                <p>together</p>
                <p>IRL</p>
                <p>in</p>
                <p>Soho,</p>
                <p>NYC.</p>
                <p>Our</p>
                <p>office</p>
                <p>doubles</p>
                <p>as</p>
                <p>an</p>
                <p>art</p>
                <p>studio,</p>
                <p>film</p>
                <p>set,</p>
                <p>and</p>
                <p>all-around</p>
                <p>creative</p>
                <p>space</p>

                <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/studio.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">studio.jpg</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/vibes.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">vibes.jpg</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/art.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">art.jpg</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/standing.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Standing on business.jpg</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token5", 1)}
                className={`token ${
                  selectedToken1 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/homies.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">homies.jpg</span>
                </div>
              </button>
              </div>

              <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/studio.webp"
                    alt="Studio"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}

{selectedToken1 === "token2" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/vibes.webp"
                    alt="Vibes"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}

{selectedToken1 === "token3" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/art.webp"
                    alt="Art"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}

{selectedToken1 === "token4" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/standing.webp"
                    alt="Standing on business"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}

{selectedToken1 === "token5" && (
                  <img
                    src="https://typowebsitevideo.s3.amazonaws.com/homies.webp"
                    alt="Homies"
                    style={{
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                )}

                
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>In</p>
                <p>order</p>
                <p>to</p>
                <p>assemble</p>
                <p>the</p>
                <p>best</p>
                <p>team,</p>
                <p>we</p>
                <p>raised</p>
                <p>over</p>
                <p>$6m</p>
                <p>in</p>
                <p>seed</p>
                <p>funding</p>
                <p>from</p>
                <p>Elad</p>
                <p>Gil,</p>
                <p>Naval</p>
                <p>Ravikant,</p>
                <p>Gokul</p>
                <p>Rajaram,</p>
                <p>Sarah</p>
                <p>Guo</p>
                <p>and</p>
                <p>the</p>
                <p>leaders</p>
                <p>of</p>
                <p>companies</p>
                <p>like</p>
                <p>Figma,</p>
                <p>Adobe,</p>
                <p>and</p>
                <p>Plaid</p>
              </div>

              <div className="bubble-bottom">
                {/* You can put content here */}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>If</p>
                <p>you're</p>
                <p>interested</p>
                <p>in</p>
                <p>working</p>
                <p>with</p>
                <p>us</p>
                <p>please</p>
                <p>fill</p>
                <p>out</p>
                <p>this</p>
                <p>form:</p>
                <button
                  onClick={() => handleTokenClick("token1", 2)}
                  className={`token ${
                    selectedToken2 === "token1" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">Typeform.com</span>
                  </div>
                </button>
              </div>

              <div className="bubble-bottom">
                {selectedToken2 === "token1" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://public-assets.typeform.com/public/favicon/apple-touch-icon.png"
                    ItemURL="https://uguyhzouvhp.typeform.com/to/b4Vgu7Yb"
                    websiteName="Apply to work with us"
                    description="Fill out this Typeform to apply to our open positions."

                    // "https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf"
                  />
                )}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>We</p>
                <p>are</p>
                <p>recruiting</p>
                <p>across</p>
                <p>product</p>
                <p>roles,</p>
                <p>but</p>
                <p>are</p>
                <p>mostly</p>
                <p>actively</p>
                <p>hiring</p>
                <p>an</p>
                <p>iOS</p>
                <p>engineer.</p>
                <p>You</p>
                <p>can</p>
                <p>find</p>
                <p>out</p>
                <p>more</p>
                <p>about</p>
                <p>that</p>
                <p>position</p>
                <p>here:</p>

                <button
                  onClick={() => handleTokenClick("token1", 3)}
                  className={`token ${
                    selectedToken3 === "token1" ? "selected" : ""
                  }`}
                >
                  <div className="tokenPill">
                    <img className="tokenimg" src={IconSVG} alt="Token" />

                    <span className="tokenText">notion.so</span>
                  </div>
                </button>
              </div>

              <div className="bubble-bottom">
                {selectedToken3 === "token1" && (
                  <PreviewBox
                    imageURL=""
                    logoURL="https://www.notion.so/front-static/favicon.ico"
                    ItemURL="https://bylines.notion.site/iOS-Engineer-at-Typo-a4869deb3db740f392387c31c7bf3fa5?pvs=4"
                    websiteName="iOS Engineer @ Typo"
                    description="Learn more about this job opening by reading through the Notion page."

                    // "https://typowebsitevideo.s3.amazonaws.com/ByLines+Deck+2.pdf"
                  />
                )}
              </div>
            </div>

            <div data-aos-once="false" data-aos="fade-up" className="bubble">
              <div className="bubble-top">
                <p>And</p>
                <p>if</p>
                <p>you</p>
                <p>don't</p>
                <p>fit</p>
                <p>the</p>
                <p>exact</p>
                <p>experience</p>
                <p>requirements,</p>
                <p>it's</p>
                <p>still</p>
                <p>worth</p>
                <p>applying.</p>
                <p>We</p>
                <p>want</p>
                <p>to</p>
                <p>work</p>
                <p>with</p>
                <p>the</p>
                <p>best,</p>
                <p>and</p>
                <p>the</p>
                <p>best</p>
                <p>come</p>
                <p>in</p>
                <p>many</p>
                <p>different</p>
                <p>forms</p>
              </div>

              <div className="bubble-bottom"></div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Job;
