import React from "react";
import { Link , useLocation } from 'react-router-dom';
// import typoLogo from './typoBlackLogo.svg';

function FloatingNavbar() {
  const location = useLocation();

  const getLinkClass = (path) => {
    return `navbar-textbox ${location.pathname === path ? "active" : ""}`;
  };
  return (
    <nav className="floating-navbar">
      <div className="navbar-content">
        <div className="navbar-logo">
          <Link to="/">
            <img src="https://typowebsitevideo.s3.amazonaws.com/black+logo.svg" alt="Logo" />
          </Link>
        </div>
        <div className="textbox-frame">
        <Link to="/waitlist" className={getLinkClass("/waitlist")}>Waitlist</Link>
        <Link to="/jobs" className={getLinkClass("/jobs")}>Jobs</Link>
          <Link to="/about" className={getLinkClass("/about")}>About</Link>
          
        </div>
      </div>
      
    </nav>
   
  );
}

export default FloatingNavbar;
