import React from 'react';
import { useNavigate } from 'react-router-dom';

function SuccessPage() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="page-content">
      <div className="success-label">
        You’re on the waitlist! We’ll reach out with an invite if you’re a good fit to try Typo :)
      </div>

      <div className="home-button-container">
        <button onClick={goHome} className="home-button">Go Home</button>
      </div>
    </div>
  );
}

export default SuccessPage;
