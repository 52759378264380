import React, { useEffect, useState, useLayoutEffect } from "react";
import linkBlack from "./IconSVG.svg";
import wavBlack from "./wavBlack.svg";
import AudioBox from "./AudioBox";
// import PdfViewer from "./PdfViewer";
import PreviewBox from "./PreviewBox";


function Page1() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [selectedToken3, setSelectedToken3] = useState("token1");
  const [LinkImage1, setLinkImage1] = useState(null);
  const [WavIcon1, setWavIcon1] = useState(null);
  const [WavIcon2, setWavIcon2] = useState(null);
  const [WavIcon3, setWavIcon3] = useState(null);
  const [WavIcon4, setWavIcon4] = useState(null);
  const [WavIcon5, setWavIcon5] = useState(null);
  const [WavIcon6, setWavIcon6] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      /* Links*/
      let svgLink1;
      if (selectedToken1 === "token3") {
        svgLink1 = linkBlack;
      } else {
        svgLink1 = linkBlack;
      }
      setLinkImage1(svgLink1);

      /* Audio*/

      let wavImage1;
      if (selectedToken1 === "token1") {
        wavImage1 = wavBlack;
      } else {
        wavImage1 = wavBlack;
      }
      setWavIcon1(wavImage1);

      let wavImage2;
      if (selectedToken1 === "token2") {
        wavImage2 = wavBlack;
      } else {
        wavImage2 = wavBlack;
      }
      setWavIcon2(wavImage2);

      let wavImage3;
      if (selectedToken2 === "token4") {
        wavImage3 = wavBlack;
      } else {
        wavImage3 = wavBlack;
      }
      setWavIcon3(wavImage3);

      let wavImage4;
      if (selectedToken3 === "token5") {
        wavImage4 = wavBlack;
      } else {
        wavImage4 = wavBlack;
      }
      setWavIcon4(wavImage4);

      let wavImage5;
      if (selectedToken3 === "token6") {
        wavImage5 = wavBlack;
      } else {
        wavImage5 = wavBlack;
      }
      setWavIcon5(wavImage5);

      let wavImage6;
      if (selectedToken3 === "token7") {
        wavImage6 = wavBlack;
      } else {
        wavImage6 = wavBlack;
      }
      setWavIcon6(wavImage6);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2, selectedToken3]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else if (bubble === 2) {
      setSelectedToken2(token);
    } else if (bubble === 3) {
      setSelectedToken3(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">
            First idea, we’re a media company… scratch that, maybe we need tech?
          </h1>
          {/* Bubble 1 */}

          <div className="bubble">
            <div className="bubble-top">
              <p>The</p>
              <p>story</p>
              <p>began</p>
              <p>because</p>
              <p>Sahil</p>
              <p>wanted</p>
              <p>to</p>
              <p>start</p>
              <p>a</p>
              <p>media</p>
              <p>company</p>
              <p>that</p>
              <p>killed</p>
              <p>Vox.</p>
              <p>The</p>
              <p>idea</p>
              <p>was</p>
              <p>to</p>
              <p>accept</p>
              <p>pitches</p>
              <p>as</p>
              <p>notes,</p>
              <p>voicenotes,</p>
              <p>or</p>
              <p>bullet-points,</p>
              <p>so</p>
              <p>we</p>
              <p>could</p>
              <p>get</p>
              <p>our</p>
              <p>smart</p>
              <p>tech</p>
              <p>friends</p>
              <p>into</p>
              <p>the</p>
              <p>media.</p>
              <p>We</p>
              <p>were</p>
              <p>“making</p>
              <p>nerds</p>
              <p>cool”</p>

              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon1}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">making nerds cool</span>
                </div>
              </button>

              <p>and</p>
              <p>we</p>
              <p>gave</p>
              <p>it</p>
              <p>the</p>
              <p>name</p>
              <p>ByLines </p>
              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon2}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Jake on what he thinks of the name "ByLines"</span>
                </div>
              </button>
              <p>—</p>
              <p>this</p>
              <p>was</p>
              <p>the</p>
              <p>cringe-worthy</p>
              <p>deck</p>

              <button
                onClick={() => handleTokenClick("token3", 1)}
                className={`token ${
                  selectedToken1 === "token3" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <img className="tokenimg" src={LinkImage1} alt="Token" />
                  <span className="tokenText">ByLines Sept 2021 Deck.pdf</span>
                </div>
              </button>
              <p>.</p>
            </div>
            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <AudioBox
                  songTitle="David & Sahil on making nerds cool, Sept 2020.m4a"
                  fileSize="674 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/David+x+Sahil+(making+nerds+cool%2C+Sept+2020).m4a"
                />
              )}
              {selectedToken1 === "token2" && (
                <AudioBox
                  songTitle="asking our friend Jake what he thinks of the name.m4a"
                  fileSize="194 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/asking+our+friend+Jake+what+he+thinks+of+the+name.m4a"
                />
              )}

{selectedToken1 === "token3" && (
  <PreviewBox 
              
              imageURL="https://typowebsitevideo.s3.amazonaws.com/Lines_Deck_1_image.png"
              logoURL="https://typowebsitevideo.s3.amazonaws.com/pdflogo.png"
              ItemURL="https://typowebsitevideo.s3.amazonaws.com/ByLines+Sept+2021+Deck.pdf"
              websiteName="Lines Deck Septemebr 2021"
              description="We connect ORIGINAL IDEAS to a large, young audience by translating them in.." />
    )}
            </div>
          </div>

          {/*Bubble 2 */}
        
          <div className="bubble">
            <div className="bubble-top">
              <p>But</p>
              <p>the</p>
              <p>more</p>
              <p>we</p>
              <p>worked</p>
              <p>with</p>
              <p>our</p>
              <p>“contributors”,</p>
              <p>the</p>
              <p>more</p>
              <p>we</p>
              <p>enjoyed</p>
              <p>reading</p>
              <p>their</p>
              <p>text</p>
              <p>threads</p>
              <p>as</p>
              <p>text</p>
              <p>threads</p>
              <p>and</p>
              <p>listening</p>
              <p>to</p>
              <p>their</p>
              <p>conversations</p>
              <p>as</p>
              <p>voicenotes</p>
              <button
                onClick={() => handleTokenClick("token4", 2)}
                className={`token ${
                  selectedToken2 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon3}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">David- how do we bring that to the masses</span>
                </div>
              </button>
              <p>.</p>
              <p>So,</p>
              <p>we</p>
              <p>decided</p>
              <p>to</p>
              <p>turn</p>
              <p>out</p>
              <p>platform</p>
              <p>into</p>
              <p>a</p>
              <p>platform</p>
              <p>for</p>
              <p>those</p>
              <p>threads.</p>
              <p>We’d</p>
              <p>trademark</p>
              <p>the</p>
              <p>“chat-UI-as-blog-post”,</p>
              <p>and</p>
              <p>let</p>
              <p>anyone</p>
              <p>upload</p>
              <p>their</p>
              <p>conversation</p>
              <p>to</p>
              <p>a</p>
              <p>URL.</p>
            </div>
            <div className="bubble-bottom">
              {selectedToken2 === "token4" && (
                <AudioBox
                  songTitle="David- how do we bring that to the masses?.m4a"
                  fileSize="94 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/David-+_how+do+we+bring+that+to+the+masses__.m4a"
                />
              )}
            </div>
          </div>
          

          <div className="bubble">
            <div className="bubble-top">
              {/*Bubble 3 */}

              <p>We</p>
              <p>built</p>
              <p>a</p>
              <p>tool</p>
              <p>that</p>
              <p>scraped</p>
              <p>your</p>
              <p>iMessage</p>
              <p>and</p>
              <p>bought</p>
              <p>the</p>
              <p>domain</p>
              <p>lines.by</p>
              <p>from</p>
              <p>a</p>
              <p>sketchy</p>
              <p>Belarusian</p>
              <p>site</p>
              <button
                onClick={() => handleTokenClick("token5", 3)}
                className={`token ${
                  selectedToken3 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon4}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">Jake x Sahil x David on Belarusian Domain</span>
                </div>
              </button>
              <p>,</p>

              <button
                onClick={() => handleTokenClick("token6", 3)}
                className={`token ${
                  selectedToken3 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon5}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">David on getting a lawyer re Belarus</span>
                </div>
              </button>
              <p>.</p>
              <p>Weirdly,</p>
              <p>a</p>
              <p>lot</p>
              <p>of</p>
              <p>people</p>
              <p>really</p>
              <p>liked</p>
              <p>it.</p>
              <button
                onClick={() => handleTokenClick("token7", 3)}
                className={`token ${
                  selectedToken3 === "token7" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon6}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">hellerstein's response to the imessage blog post</span>
                </div>
              </button>
              <p>The</p>
              <p>format</p>
              <p>felt</p>
              <p>refreshing</p>
              <p>—</p>
              <p>the</p>
              <p>content</p>
              <p>was</p>
              <p>somewhere</p>
              <p>between</p>
              <p>public</p>
              <p>and</p>
              <p>private.</p>
              <p>We</p>
              <p>became</p>
              <p>obsessed</p>
              <p>with</p>
              <p>the</p>
              <p>messaging</p>
              <p>format.</p>
            </div>

            <div className="bubble-bottom">
              {selectedToken3 === "token5" && (
                <AudioBox
                  songTitle="Jake x Sahil x David on Belarusian Domain.m4a"
                  fileSize="211 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/Jake+x+Sahil+x+David+on+Belarusian+Domain.m4a"
                />
              )}

              {selectedToken3 === "token6" && (
                <AudioBox
                  songTitle="David on getting a lawyer re Belarus.m4a"
                  fileSize="251 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/David+on+getting+a+lawyer+re+Belarus.m4a"
                />
              )}

              {selectedToken3 === "token7" && (
                <AudioBox
                  songTitle="hellerstein's response to the imessage blog post.m4a"
                  fileSize="531 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/hellerstein's+response+to+the+imessage+blog+post.m4a"
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page1;
