import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './WhatNavigator.module.css';

function WhatNavigator({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    // Mapping paths to a sequence to determine next/prev paths
    const paths = [
        "/studioday/what/drawing" ,
        "/studioday/what/compose",
        "/studioday/what/chat",
        "/studioday/what/consume",
        "/studioday/what/preview",
        "/studioday/what/expand",
    ];
    const currentIndex = paths.indexOf(location.pathname);

    const handlePrevious = () => {
        if (currentIndex > 0) {
            navigate(paths[currentIndex - 1]);
        }
    };

    const handleNext = () => {
        if (currentIndex < paths.length - 1) {
            navigate(paths[currentIndex + 1]);
        }
    };

    return (
        <div className={styles.pageContainer}>
            {children}

            <div className={styles.bottomBar}>
                <div className={styles.buttonsFrame}>
                    <button 
                        onClick={handlePrevious} 
                        className={styles.previousButton} // Adjust class name as per your CSS
                        disabled={currentIndex === 0}
                    >
                        Previous
                    </button>
                    <button 
                        onClick={handleNext} 
                        className={styles.nextButton} // Adjust class name as per your CSS
                        disabled={currentIndex === paths.length - 1}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default WhatNavigator;