import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import AudioBox from "./AudioBox";

function Page3() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [WavIcon1, setWavIcon1] = useState(null);
  const [WavIcon2, setWavIcon2] = useState(null);
  const [WavIcon3, setWavIcon3] = useState(null);

  useEffect(() => {
    async function loadSvg() {


      let wavImage1;
      if (selectedToken1 === "token4") {
        wavImage1 = wavBlack;
      } else {
        wavImage1 = wavBlack;
      }
      setWavIcon1(wavImage1);


      let wavImage2;
      if (selectedToken1 === "token5") {
        wavImage2 = wavBlack;
      } else {
        wavImage2 = wavBlack;
      }
      setWavIcon2(wavImage2);

      let wavImage3;
      if (selectedToken1 === "token6") {
        wavImage3 = wavBlack;
      } else {
        wavImage3 = wavBlack;
      }
      setWavIcon3(wavImage3);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">Meeting an investor.</h1>
          {/* Bubble 1 */}
          <div className="bubble">
            <div className="bubble-top">
            <p>The</p>
<p>first</p>
<p>investor</p>
<p>we</p>
<p>ever</p>
<p>met</p>
<p>was</p>
<p>called</p>
<p>Daz.</p>
<p>He’s</p>
<p>a</p>
<p>British</p>
<p>dude</p>
<p>from</p>
<p>Leeds</p>
<p>who</p>
<p>runs</p>
<p>a</p>
<p>sports</p>
<p>betting</p>
<p>company,</p>
<p>drives</p>
<p>on</p>
<p>the</p>
<p>F1</p>
<p>circuit</p>
<button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Daz1.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Dashing Daz 1.png</span>
                </div>
              </button>

              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src="https://typowebsitevideo.s3.amazonaws.com/Daz2.webp"
                      alt="Token Thumbnail"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Dashing Daz 2.png</span>
                </div>
              </button>
<p>and</p>
<p>reads</p>
<p>Heidegger</p>
<p>in</p>
<p>his</p>
<p>spare</p>
<p>time.</p>
<p>He</p>
<p>isn’t</p>
<p>a</p>
<p>VC,</p>
<p>but</p>
<p>Sahil</p>
<p>had</p>
<p>known</p>
<p>him</p>
<p>for</p>
<p>a</p>
<p>year</p>
<p>years,</p>
<p>and</p>
<p>decided</p>
<p>to</p>
<p>give</p>
<p>him</p>
<p>the</p>
<p>pitch.</p>
<p>He</p>
<p>was</p>
<p>the</p>
<p>first</p>
<p>person</p>
<p>outside</p>
<p>of</p>
<p>the</p>
<p>three</p>
<p>of</p>
<p>us</p>
<p>who</p>
<p>thought</p>
<p>we</p>
<p>were</p>
<p>actually</p>
<p>doing</p>
<p>something</p>
<p>interesting</p>
<button
                onClick={() => handleTokenClick("token4", 1)}
                className={`token ${
                  selectedToken1 === "token4" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon1}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">Daz amazed at the app (fall 2020).m4a</span>
                </div>
              </button>
              <button
                onClick={() => handleTokenClick("token5", 1)}
                className={`token ${
                  selectedToken1 === "token5" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon2}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">Daz on the gravity of the idea (Nov 2021).m4a</span>
                </div>
              </button>
<p>David</p>
<p>and</p>
<p>Sahil</p>
<p>made</p>
<p>a</p>
<p>resolution</p>
<p>to</p>
<p>never</p>
<p>take</p>
<p>money</p>
<p>from</p>
<p>any</p>
<p>grifters.</p>


<button
                onClick={() => handleTokenClick("token6", 1)}
                className={`token ${
                  selectedToken1 === "token6" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon3}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>
                  <span className="tokenText">David and Sahil on value aligned investors.m4a</span>
                </div>
              </button>



            </div>

            <div className="bubble-bottom">
            {selectedToken1 === "token1" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Daz1.webp"
                  alt="Daz1"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}

              {selectedToken1 === "token2" && (
                <img
                  src="https://typowebsitevideo.s3.amazonaws.com/Daz2.webp"
                  alt="Daz2"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                  }}
                />
              )}



              {selectedToken1 === "token4" && (
                <AudioBox
                  songTitle="Daz amazed at the app (fall 2020).m4a"
                  fileSize="981 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/Daz+amazed+at+the+app+(fall+2020).m4a"
                />
              )}


{selectedToken1 === "token5" && (
                <AudioBox
                  songTitle="Daz on the gravity of the idea (Nov 2021).m4a"
                  fileSize="511 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/Daz+on+the+gravity+of+the+idea%2C+software+is+shitty+filing+cabinets+(Nov+2021).m4a"
                />
              )}

{selectedToken1 === "token6" && (
                <AudioBox
                  songTitle="David and Sahil on value aligned investors.m4a"
                  fileSize="271 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/David+and+Sahil+on+what+Daz+brings%2C+value+aligned+investors.mov"
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page3;
