import React, { useEffect, useState, useLayoutEffect } from "react";
import wavBlack from "./wavBlack.svg";
import AudioBox from "./AudioBox";

function Page8() {
  const [selectedToken1, setSelectedToken1] = useState("token1");
  const [selectedToken2, setSelectedToken2] = useState("token1");
  const [WavIcon1, setWavIcon1] = useState(null);
  const [WavIcon2, setWavIcon2] = useState(null);

  useEffect(() => {
    async function loadSvg() {
      let wavImage1;
      if (selectedToken1 === "token1") {
        wavImage1 = wavBlack;
      } else {
        wavImage1 = wavBlack;
      }
      setWavIcon1(wavImage1);

      let wavImage2;
      if (selectedToken1 === "token2") {
        wavImage2 = wavBlack;
      } else {
        wavImage2 = wavBlack;
      }
      setWavIcon2(wavImage2);
    }

    loadSvg();
  }, [selectedToken1, selectedToken2]);

  const handleTokenClick = (token, bubble) => {
    if (bubble === 1) {
      setSelectedToken1(token);
    } else {
      setSelectedToken2(token);
    }
  };

  useEffect(() => {
    // This function sets a CSS variable with the window height
    function setWindowHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run the function on load and resize
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", setWindowHeight);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useLayoutEffect(() => {}, []);

  return (
    <div className="app-container">
      {
        <div className="old-main-content">
          <h1 className="super-title">
            The company almost died because of an Azure bill.
          </h1>
          {/* Bubble 1 */}

                  
          <div className="bubble">
            <div className="bubble-top">
              <p>We</p>
<p>got</p>
<p>hit</p>
<p>with</p>
<p>a</p>
<p>$3,000</p>
<p>Azure</p>
<p>Bill.</p>
<p>David</p>
<p>realized</p>
<p>he’d</p>
<p>forgotten</p>
<p>to</p>
<p>change</p>
<p>a</p>
<p>default</p>
<p>setting</p>
<p>that</p>
<p>had</p>
<p>racked</p>
<p>up</p>
<p>a</p>
<p>bunch</p>
<p>of</p>
<p>charges.</p>
<p>At</p>
<p>this</p>
<p>point,</p>
<p>we</p>
<p>really,</p>
<p>really</p>
<p>needed</p>
<p>money.</p>

            </div>
            
            <div className="bubble-bottom">
              {/* You can put content here */}
            </div>
          </div>
          <div className="bubble">
            <div className="bubble-top">
              <p>We</p>
              <p>got</p>
              <p>an</p>
              <p>offer</p>
              <p>from</p>
              <p>one</p>
              <p>investor</p>
              <p>who</p>
              <p>liked</p>
              <p>us</p>
              <p>but</p>
              <p>hated</p>
              <p>our</p>
              <p>idea.</p>
              <p>The</p>
              <p>offer</p>
              <p>was</p>
              <p>$100k</p>
              <p>for</p>
              <p>10%</p>
              <p>of</p>
              <p>the</p>
              <p>company,</p>
              <p>which</p>
              <p>would</p>
              <p>have</p>
              <p>valued</p>
              <p>the</p>
              <p>company</p>
              <p>at</p>
              <p>$1million.</p>
              <button
                onClick={() => handleTokenClick("token1", 1)}
                className={`token ${
                  selectedToken1 === "token1" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon1}
                      alt="rasing"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">Rejecting $100k.</span>
                </div>
              </button>
              <p>David</p>
              <p>and</p>
              <p>Eli</p>
              <p>wanted</p>
              <p>us</p>
              <p>to</p>
              <p>take</p>
              <p>the</p>
              <p>cash,</p>
              <p>but</p>
              <p>Sahil</p>
              <p>was</p>
              <p>worried</p>
              <p>it</p>
              <p>would</p>
              <p>set</p>
              <p>us</p>
              <p>up</p>
              <p>for</p>
              <p>failure</p>
              <p>in</p>
              <p>the</p>
              <p>long</p>
              <p>term:</p>
              <p>our</p>
              <p>first</p>
              <p>investor</p>
              <p>being</p>
              <p>someone</p>
              <p>who</p>
              <p>didn’t</p>
              <p>believe</p>
              <p>in</p>
              <p>our</p>
              <p>vision</p>
              <p>and</p>
              <p>owned</p>
              <p>such</p>
              <p>a</p>
              <p>drastic</p>
              <p>portion</p>
              <p>of</p>
              <p>the</p>
              <p>company.</p>
              <p>David</p>
              <p>suggested</p>
              <p>that</p>
              <p>we</p>
              <p>started</p>
              <p>making</p>
              <p>revenue</p>
              <p>off</p>
              <p>our</p>
              <p>0</p>
              <p>users</p>{" "}
              <button
                onClick={() => handleTokenClick("token2", 1)}
                className={`token ${
                  selectedToken1 === "token2" ? "selected" : ""
                }`}
              >
                <div className="tokenPill">
                  <div className="thumbnail">
                    <img
                      src={WavIcon2}
                      alt="Token"
                      className="thumbnailImg"
                    ></img>
                  </div>

                  <span className="tokenText">
                    {" "}
                    post-hyper talking about how we can't raise.
                  </span>
                </div>
              </button>
            </div>

            <div className="bubble-bottom">
              {selectedToken1 === "token1" && (
                <AudioBox
                  songTitle="Rejecting $100k.mp3"
                  fileSize="131 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/cofounders+on+what+we'd+dream+of+building%2C+august+2021.m4a"
                />
              )}

              {selectedToken1 === "token2" && (
                <AudioBox
                  songTitle="cofounders post-hyper talking about how we can't raise.mp3"
                  fileSize="611 KB"
                  audioSrc="https://typowebsitevideo.s3.amazonaws.com/cofounders+post-hyper+talking+about+how+we+can't+raise.mov"
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Page8;
