import React from 'react';
import QRcode from './QRcode.svg';
import './App.css';

const VIP = () => {
  return (
    <div className="qr-container">
      <img src={QRcode} alt="VIP link" className="qr-image" />
    </div>
  );
}

export default VIP;
